import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const gradientMove = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

// Styling
const FaqPageContainer = styled.div`
  background: linear-gradient(135deg, #000, #333);
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MainFaqSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 30px;
`;

const MainFaqItem = styled.div`
  background-color: #222;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  animation: ${fadeIn} 0.5s ease-out;

  &:hover {
    background-color: #333;
  }
`;

const MainFaqTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(to right, white, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MainFaqText = styled.div`
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  max-width: 800px;
  margin: 0 auto;
`;

const Link = styled.a`
  color: #00B0FF;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Ol = styled.ol`
  padding-left: 20px;
  padding-bottom: 20px;
`;

const Ul = styled.ul`
  padding-left: 20px;
  list-style-type: disc;
  padding-bottom: 20px;
`;

const FaqBoxesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  width: 100%;
`;

const FaqBox = styled.div`
  background-color: #222;
  border-radius: 8px;
  padding: 20px;
  width: calc(33% - 40px);
  min-width: 250px;
  flex-grow: 1;
  cursor: pointer;
  transition: background 0.5s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(120deg, rgba(255,255,255,0.1), rgba(0,0,0,0.1), rgba(255,255,255,0.1));
    background-size: 200% 200%;
    z-index: -1;
    transition: opacity 0.5s ease;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
    animation: ${gradientMove} 2s infinite;
  }

  @media (max-width: 768px) {
    width: calc(50% - 40px);
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const FaqBoxTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  background: linear-gradient(to right, white, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const FaqBoxContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  margin-top: 10px;
  color: #ccc;
  animation: ${fadeIn} 0.5s ease-out;
`;



// Component
const FaqPage = () => {
  const [openBox, setOpenBox] = useState(null);

  const toggleBox = (box) => {
    setOpenBox(openBox === box ? null : box);
  };


  return (
    <FaqPageContainer>
      <MainFaqSection>
        <MainFaqItem>
          <MainFaqTitle>Get Started With Humble Futures Funding</MainFaqTitle>
          <MainFaqText>
          At our firm, we are dedicated to fostering the growth of talented traders through a transparent, fair, and supportive trading environment. We offer both Standard and Premium Performance Accounts to cater to diverse trading needs, with the Premium option providing faster payouts and higher maximum withdrawals. Our competitive fees, expert support, and comprehensive educational resources ensure a clear path to trading success. With state-of-the-art trading platforms and flexible trading options, we empower traders to excel. Unlike other firms, we prioritize our traders' success, allowing them to keep 100% of their net profits up to $25,000 and providing innovative solutions for consistent growth. Join us to experience superior support and achieve your trading goals with confidence.          </MainFaqText>
        </MainFaqItem>
        <MainFaqItem>
          <MainFaqTitle>Registration Process</MainFaqTitle>
            <MainFaqText>
                To begin your trading journey with Humble Futures Funding, follow these steps:

                <Ol>
                  <li>First, purchase an evaluation account from  the standard or express path, with the account size you prefer.</li>
                  <li>Provide the proper information upon checkout, ensuring the name matches your government-issued ID.</li>
                  <li>
                    Upon successful purchase, you will receive an email with your credentials to R Trader Pro, as well as links to download R Trader and Quantower, our recommended trading platform, within 24 hours.
                  </li>
                  <li>
                    Before logging into Quantower, download R Trader, log in, and agree to the data fee agreements from Rithmic. <strong> without agreeing to these terms, your Quantower login will not work!</strong> Agree specifically to Non-Professional unless you prefer to pay extra for a professional market subscription.
                  </li>
                  <li>
                    After completing these steps, log into Quantower. If you need a visual aid, you can watch the video <Link href="#">here</Link>.
                  </li>
                  <li>
                    Once a trader successfully passes their evaluation account, contact support@humblefunding.co or open a Discord ticket for Support to validate the account.
                  </li>
                  <li>
                    Upon validation, you will receive an email congratulating you on passing the evaluation test. This email will include a certificate and links to purchase a performance account. There will be three options:
                    <Ul>
                      <li>A monthly pay option for the standard performance account.</li>
                      <li>A lifetime fee + one-day pass option for the standard performance account.</li>
                      <li>A lifetime fee + one-day pass option for a premium performance account.</li>
                    </Ul>
                  </li>
                  <li>
                    After purchasing a performance account, you need to hit the required profit target for each performance account to qualify for a payout.
                  </li>
                  <li>
                    After qualifying for a payout request, you must wait 8 trading days, making an active trade each day, before you can email support@humblefunding.co to submit the payout request. <strong>Important note:</strong> Once a payout request has been submitted for a performance account, you cannot submit another payout request for that same account until the payout spread for the first payout request is complete. This rule applies only to the same account. For other performance accounts without a payout request, you can submit a request in the next payment period.
                  </li>
                </Ol>
              </MainFaqText>
        </MainFaqItem>
      </MainFaqSection>

      <FaqBoxesContainer>
        <FaqBox onClick={() => toggleBox('evaluation')}>
          <FaqBoxTitle>Evaluation Account Process</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'evaluation'}>
            <p>Traders showcase their trading abilities by operating in a simulated account that mirrors real market scenarios.</p>
          </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('platforms')}>
          <FaqBoxTitle>Supported Trading Platforms</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'platforms'}>
            <p>
            • Quantower (Partner Platform)<br />
            • Rithmic Pro<br />
            • Finamark Systems (Browser-based)<br />
            • MetaTrader 5 (MT5)<br />
            • Investor/RT (Linnsoft)<br />
            • ATAS<br />
            • TradingView<br />
            • Motive Wave (Mac, Windows, Linux)<br />
            • CTS T4<br />
            • Jigsaw Daytradr<br />
            • Bookmap X-Ray<br />
            • OptimusFLOW<br />
            • CQG Desktop<br />
            • Trade Navigator<br />
            • VolFix<br />
            • Sierra Charts<br />
            • Multicharts<br />
            • TradeStation<br />
            • EdgeProX (Mac, Windows, Linux)<br />
          </p>                
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('capital')}>
        <FaqBoxTitle>What are the initial capital requirements for opening a Performance Account?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'capital'}>
            <p>
            <strong>Initial Capital Requirements for Performance Accounts:</strong><br />
            At Humble Futures Funding, traders are not required to deposit any of their own capital to open an Evaluation or Performance Account. Unlike traditional trading setups where you need to risk your own money, Humble Futures Funding allows you to trade using our capital. This means you can take advantage of all the benefits and profit potential without putting your own funds at risk. Our model is designed to let you showcase your trading skills and achieve financial success by leveraging our resources, ensuring that the only risk you take is in your strategy, not in your personal finances.
            </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('threshold')}>
        <FaqBoxTitle>Understanding the Trailing Threshold?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'threshold'}>
          <p>
          The trailing threshold serves as a dynamic risk management mechanism that adjusts the allowable loss limit according to a trader's peak account balance. If the account balance drops below this limit due to losses, it may result in failing the evaluation. This system promotes prudent trading by incentivizing traders to secure gains and minimize losses, thus highlighting the importance of robust risk management strategies.
          </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('requestpayout')}>
        <FaqBoxTitle>Payout Information</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'requestpayout'}>
            <p>
            After completing the necessary 8 trading days, you become eligible to request a payout from your standard or premium performance account(s). We have simplified the payout request process to make accessing your earnings easy and efficient.
            <br /><br />
            <strong>How To Request Your Payout:</strong>
            <br />
            For a payout, either email us with your account information and withdrawal amount at support@humblefunding.co, or use the 'Request Payout' feature on the main page of the website. Enter the desired amount and confirm your Rithmic ID and Performance account name to complete the request.            <br />
            <br /><br />
            <strong>How Long To Recieve Payout:</strong>
            <br />
              Payout requests can take from anywhere up to 48 hours - 72 hours.
            <br /><br />
            <strong>Enhancing Your Profit Potential:</strong><br />
            Handling Multiple Accounts: Utilize the ability to oversee up to 25 funded accounts in one payout window, greatly enhancing your earning capacity. 
            <br />
            <strong>30% Profit Consistency Rule for Standard and Premium Performance Accounts:</strong><br />
            This rule, relevant only to live simulated performance accounts, ensures that a single trading day's net profit does not exceed 30% of your total net profit over the necessary 8 trading days when requesting payouts. This applies to both standard and premium performance accounts, maintaining consistent trading practices and encouraging disciplined profit management across all account types.
            <br />
            <br /><br />
            <strong>What To Do For Exceeding 30% Threshold:</strong><br />
            Should you surpass this limit, continue to employ effective trading strategies. Over time, as your total net profit increases, the influence of a single high-profit day will diminish.
            <br /><br />
            <strong>Payout Policy:</strong><br />
            Our payout policy is designed for simplicity and trader benefit. Traders retain 100% of their net profits up to $25,000. After this threshold, a profit-sharing model kicks in, allowing traders to keep 90% of their net profits. This approach promotes a sustainable and advantageous relationship. Upon reaching a net profit mark of $25,000, traders can continue to trade with live capital under this profit-sharing agreement. Additionally, traders have the opportunity to reapply every 15 days to validate their eligibility.
            <br /><br />
          </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('cons')}>
        <FaqBoxTitle>Consistency Rule</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'cons'}>
          <p>
            At Humble Futures Funding, the consistency rule varies across different paths:
            <br/><br/>
            For the <strong>Standard Path</strong>, the <strong>30% consistency rule</strong> applies to both <strong>evaluation accounts</strong> and <strong>performance accounts</strong>. This rule mandates that <em>no single trading day’s profit should account for 30% or more of your total profits</em>. This means evaluation accounts on the standard path need to be traded for a minimum of 5 days. Exceeding this limit means you won't be able to withdraw profits until consistency is restored.
          </p>
          <br/>
          <p>
            On the <strong>Express Path</strong>, the 30% consistency rule is applicable only to <strong>performance accounts</strong>. Evaluation accounts under this path are exempt from the consistency rule, providing a more flexible evaluation period.
          </p>
          <br/>
          <p>
            For the <strong>Instant Funding Path</strong>, a <strong>30% consistency rule</strong> is in place, applicable solely to this path's performance accounts. This means that no single trading day’s profit should constitute 20% or more of your total profits.
          </p>
          <br/>
          <p>
            <strong>Example:</strong> If your total simulated profits are $10,000 and one day's profit is $3,500 on a Standard Path performance account, surpassing the 30% threshold, you will need to continue trading and generate additional profits. For instance, earning an extra $2,000 to bring the total to $12,000 would adjust the $3,500 profit to fall below the 30% limit, thus allowing you to withdraw your earnings.
          </p>
          <br/>
          <p>
          Importantly, <strong>breaching the consistency rule will not always result in immediate termination of your account.</strong> In most cases, traders will simply need to correct the consistency by continuing to trade and achieving further profits until the requirement is met. However, <strong>Humble Futures Funding reserves the right to enforce stricter consequences, including account resets or, in severe or repeat cases, termination of the account</strong> for abuse of the rule. This is particularly relevant for certain paths and for traders who repeatedly violate the consistency rule.
          </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('cancelSubscription')}>
          <FaqBoxTitle>How to Cancel Account Subscriptions?</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'cancelSubscription'}>
            <p>
              To cancel your subscription, it is the trader's responsibility to either <strong>email the support team</strong> at <a href="mailto:support@humblefunding.co">support@humblefunding.co</a> or <strong>open a support ticket</strong> in the official Discord server. These are the only accepted methods for subscription cancellations.
              <br /><br />
              <strong>Important:</strong> If you do not cancel your subscription before the next billing cycle and are rebilled, you will not be eligible for a refund. Please ensure that cancellation requests are made in advance to avoid unwanted charges.
              <br /><br />
              For traders who wish to manage their accounts effectively, we recommend reviewing your subscription status regularly to prevent any unexpected renewals. Once a cancellation request is received, you will be notified of the successful termination of your subscription.
              <br /><br />
              Humble Futures Funding is committed to transparent and fair policies, and we encourage all traders to reach out promptly if they have any concerns or questions regarding their subscriptions.
            </p>
          </FaqBoxContent>
        </FaqBox>

      

        <FaqBox onClick={() => toggleBox('profittarget')}>
        <FaqBoxTitle> What happens if I don't meet the profit target in the Evaluation Account?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'profittarget'}>
          <p>
          If you don't meet the profit target within the evaluation period, you can continue trading until your subscription ends. You may also renew your subscription to keep trading until you meet the target. There's no penalty for not meeting the target; however, the account will need to be reset if you breach any of the account's rules.
          </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('copytrading')}>
          <FaqBoxTitle>Copy Trading and Account Limits</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'copytrading'}>
            <p>
              At <strong>Humble Futures Funding</strong>, we understand that some traders may wish to utilize copy trading as part of their strategy. 
              <br/><br/>
              <strong>Copy Trading Policy:</strong><br/>
              Copy trading is permitted, allowing traders to mirror trades across multiple accounts as long as all trading practices remain within the firm's rules. Traders using copy trading should still adhere to all risk management and trading rules, including scalping and high-frequency trading restrictions, as these rules will be enforced across all accounts.
              <br/><br/>
              <strong>Account Limit:</strong><br/>
              To ensure responsible and manageable trading, there is a limit of <strong>25 funded or performance accounts</strong> that can be held simultaneously. This cap allows traders to maximize their earning potential while maintaining a disciplined and controlled trading approach.
              <br/><br/>
              Humble Futures Funding encourages traders to leverage these options responsibly to enhance their trading experience, maximize their profits, and maintain alignment with firm policies.
            </p>
          </FaqBoxContent>
        </FaqBox>


        <FaqBox onClick={() => toggleBox('expresspath')}>
          <FaqBoxTitle>What is the Express Path?</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'expresspath'}>
            <p>
              The <strong>Express Path</strong> is designed to accelerate your journey to becoming a funded partner with Humble Futures Funding. This path simplifies the process by allowing traders to pay only a <strong>monthly subscription fee</strong>, eliminating the need to purchase an evaluation and pay an activation fee separately. The goal is to make it <strong>quicker and easier</strong> for traders to get funded.
              <br/><br/>
              Special considerations for the Express Path include the <strong>absence of a consistency rule</strong> for the evaluation account. However, once you move to the performance account, a <strong>30% consistency rule</strong> will apply. We recommend reviewing the consistency rule section in the FAQ to fully understand the requirements.
              <br/><br/>
              After passing the evaluation, there will be a <strong>4-6 day probationary period</strong> where Humble Futures Funding analysts review your trading orders. During this time, analysts ensure that you are practicing <strong>proper risk mitigation strategies</strong> and not engaging in any <strong>prohibited trading practices</strong>. Once approved, you will receive your certificate and be assigned a <strong>standard performance account</strong>!
              <br/><br/>
              Regarding payout requests, once you pass the performance account, you must have traded for a <strong>minimum of 8 days</strong>. For instance, if you pass on the 6th trading day, you will still need to make trades for two more days to qualify for a payout request. <strong>Payout requests are reviewed and approved on the same day</strong>, enabling you to access your funds quickly!
              <br/><br/>
              After the initial passing of the performance account and upon the first successful payout, traders will be able to request withdrawals as soon as they hit <strong>$500 or multiples of $500</strong>. These payouts will follow a <strong>3-month payout spread</strong>. However, if a trader reaches the <strong>minimum withdrawal amount</strong> for their PA account size, they will return to the default of <strong>same-day payouts with no spread</strong>. 
              <br/><br/>
              <strong>Example:</strong> If a trader hits $500, they can request a withdrawal, which will be split into three monthly payments of $166.67. If they reach the PA minimum withdrawal amount (e.g., $2,000 for some account sizes), they qualify for same-day payouts with the option to choose a payout spread for future requests.
            </p>
          </FaqBoxContent>
        </FaqBox>
      

        <FaqBox onClick={() => toggleBox('instantfundingpath')}>
          <FaqBoxTitle>What is the Instant Funding Path?</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'instantfundingpath'}>
            <p>
              The <strong>Instant Funding Path</strong> is tailored for traders who have already demonstrated their skills and received payouts from other firms or with Humble Futures Funding. This path allows experienced traders to bypass the evaluation stage and gain immediate access to a funded performance account.
              <br/><br/>
              To qualify for the Instant Funding Path, traders are required to provide <strong>picture proof of being funded</strong> with other firms or Humble Futures Funding. This includes <strong>proof of receiving payouts</strong>, which can be in the form of a bank statement or a screenshot of the payout from platforms like RYZE. 
              <br/><br/>
              <strong>Important:</strong> If a trader fails to provide proof of payout with another firm before or at the time of purchase, their account will be at risk of <strong>termination</strong>, and the trader may also face a potential <strong>ban from Humble Futures Funding</strong>. Ensuring proof of funding is provided is crucial to maintaining the account.
              <br/><br/>
              The Instant Funding Path imposes a <strong>30% consistency rule</strong> on performance accounts. Additionally, traders must complete a <strong>minimum of 8 trading days</strong> before being eligible for a payout request. After the first payout, the minimum trading days requirement increases to <strong>10 days</strong>.
              <br/><br/>
              To advance from a performance account to a live trading account, traders must accrue <strong>total profits of 25K</strong> from their payouts. Once this milestone is reached, traders will be eligible to trade a live account with the profits they have earned.
              <br/><br/>
              The Instant Funding Path is designed to reward proven traders by providing them with a fast path to accessing capital, enabling them to scale their trading journey efficiently.
            </p>
          </FaqBoxContent>
        </FaqBox>
      
    
    <FaqBox onClick={() => toggleBox('payoutguidelines')}>
        <FaqBoxTitle>Payout Guidelines</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'payoutguidelines'}>
            <p>
            At Humble Futures Funding, we are committed to innovating the trading industry by providing transparent and fair withdrawal guidelines. Below is a structured breakdown of the minimum account balances, trading day requirements for <strong>performance accounts</strong>, maximum withdrawal limits, and other essential details for each account size.
            <br /><br />
            <strong>Account Size and Withdrawal Limits for Performance Accounts:</strong><br />
            <ul>
                <li><strong>$25,000 Account</strong> – Minimum Balance: <u>Standard PA</u>: $26,250 | <u>Premium PA</u>: $27,500 | Trading Days Required for Performance Accounts: 8 | Maximum Payout Per Standard PA: $1,000 | Maximum Payout Per Premium PA: $1,500</li>
                <li><strong>$50,000 Account</strong> – Minimum Balance: <u>Standard PA</u>: $52,500 | <u>Premium PA</u>: $53,500 | Trading Days Required for Performance Accounts: 8 | Maximum Payout Per Standard PA: $1,500 | Maximum Payout Per Premium PA: $2,000</li>
                <li><strong>$75,000 Account</strong> – Minimum Balance: <u>Standard PA</u>: $78,500 | <u>Premium PA</u>: $79,500 | Trading Days Required for Performance Accounts: 8 | Maximum Payout Per Standard PA: $1,750 | Maximum Payout Per Premium PA: $2,000</li>
                <li><strong>$100,000 Account</strong> – Minimum Balance: <u>Standard PA</u>: $105,500 | <u>Premium PA</u>: $106,500 | Trading Days Required for Performance Accounts: 8 | Maximum Payout Per Standard PA: $2,000 | Maximum Payout Per Premium PA: $2,500</li>
                <li><strong>$150,000 Account</strong> – Minimum Balance: <u>Standard PA</u>: $158,000 | <u>Premium PA</u>: $159,500 | Trading Days Required for Performance Accounts: 8 | Maximum Payout Per Standard PA: $2,500 | Maximum Payout Per Premium PA: $3,000</li>
                <li><strong>$300,000 Account</strong> – Minimum Balance: <u>Standard PA</u>: $319,000 | <u>Premium PA</u>: $320,000 | Trading Days Required for Performance Accounts: 8 | Maximum Payout Per Standard PA: $3,000 | Maximum Payout Per Premium PA: $4,000</li>
            </ul>
            <br /><br />
            <strong>Important:</strong><br />
            The 8 trading days requirement applies <u>only to performance accounts</u> and is not applicable to evaluation accounts.
            <br /><br />
            <strong>Enhanced Withdrawal Policy:</strong><br />
            This withdrawal policy promotes active trading and ensures financial stability within performance accounts. It provides traders with a clear understanding of their withdrawal capacities and requirements.
            <br /><br />
            <strong>Standard vs. Premium Performance Accounts:</strong><br />
            - <u>Standard Performance Accounts (PA)</u> allow traders to receive their payout immediately upon request approval, with an option to choose a 3-month payout spread that includes 2 free evaluation account resets.<br />
            - <u>Premium Performance Accounts (PA)</u> offer higher payout requests, and if traders opt for the 2-month payout spread, they receive an added benefit of 3 free evaluation account resets.
            <br /><br />
            <strong>Extended Withdrawal Benefits:</strong><br />
            From the fourth month of withdrawals for the same account, there is no maximum limit on the withdrawal amount, provided the account maintains the required minimum balance.
            <br /><br />
            Humble Futures Funding remains dedicated to fostering an environment of financial security and growth for our traders. We believe these guidelines will help promote disciplined and profitable trading practices.
            </p>
        </FaqBoxContent>
    </FaqBox>




    <FaqBox onClick={() => toggleBox('vs')}>
        <FaqBoxTitle>What is the difference between a Standard and Premium Performance Account?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'vs'}>
          <p>
            The <strong>Standard Performance Account</strong> allows traders to receive their payout immediately upon request approval. Traders also have the option to choose a 3-month payout spread, which includes 2 free evaluation account resets. 
            <br /><br />
            The <strong>Premium Performance Account</strong> allows for trader to make higher payout requests while still recieving their payout immediately upon approval. It also offers a 2-month payout spread option, along with an added benefit of 3 free evaluation account resets. This account is tailored for traders who want to optimize their payout strategy and capitalize on their trading success while benefiting from enhanced account features.
            <br /><br />
            It's important to note that the Standard and Premium Performance Accounts are separate purchase options—you cannot upgrade from a Standard to a Premium Performance Account.
          </p>
        </FaqBoxContent>
    </FaqBox>

    <FaqBox onClick={() => toggleBox('activationFee')}>
        <FaqBoxTitle>What are the Activation Fee Costs?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'activationFee'}>
          <p>
            <strong>Important:</strong> Activation fees apply strictly to accounts under the <strong>Standard Path</strong>. These fees do not apply to Express accounts. Below are the activation options available:
            <br /><br />
            <strong>1. $79.99 Monthly Fee:</strong><br />
            - Traders opting for this fee must first successfully pass their <strong>evaluation account</strong>. After passing, if they choose the $79.99 monthly option, they will need to trade for an additional <strong>5 days</strong> in the same account before receiving their Performance Account.<br />
            - There is no specific profit target or trading volume required during these 5 days—traders only need to execute at least one trade per day. This process helps track trading activity and ensures consistency while allowing traders to maintain their momentum after passing the evaluation. It’s designed to reinforce good trading habits and provide additional data to help us better support traders as they transition to their Performance Account.
            <br /><br />
            <strong>2. $180 Lifetime Fee + One Day Pass:</strong><br />
            - This is a one-time payment that grants <strong>instant access</strong> to a <strong>Standard Performance Account (PA)</strong>.<br />
            - No additional waiting period is required.
            <br /><br />
            <strong>3. $210 Lifetime Fee + One Day Pass:</strong><br />
            - This one-time payment grants <strong>instant access</strong> to a <strong>Premium Performance Account (PA)</strong>, which includes enhanced payout options and other benefits.
            <br /><br />
            These options allow traders under the Standard Path to choose the best fit for their trading journey, whether they prefer a monthly fee or a lifetime activation fee for instant access to their Performance Account.
          </p>
        </FaqBoxContent>
    </FaqBox>
  

      
    <FaqBox onClick={() => toggleBox('rulepa')}>
      <FaqBoxTitle>Standard and Premium Performance Account Trading Rules</FaqBoxTitle>
      <FaqBoxContent isOpen={openBox === 'rulepa'}>
        <p>
          <strong>Max Contract Limit:</strong> Traders are not permitted to use the maximum contract limits exclusively for speculative trades designed to generate abrupt profit increases. These limitations are carefully regulated for each account type to ensure disciplined trading. Exceeding these limits without a strategic basis can result in account review and potential penalties, reinforcing our commitment to responsible trading practices.<br /><br />
    
          <strong>30% Consistency Rule:</strong> Traders must ensure that no single trading day’s net profit exceeds 30% of their total net profit during the required trading period. For example, if a trader’s total net profit is $10,000, no single day should contribute more than $3,000. Breaching this rule may result in denied payouts or account termination, as it is designed to encourage disciplined and consistent trading.<br /><br />
    
          <strong>Scalping and High-Frequency Trading (HFT) Restrictions:</strong> Humble Futures Funding emphasizes the importance of deliberate and well-planned trading approaches, strictly prohibiting practices that encourage excessive risk-taking or speculative behavior. To maintain a responsible trading environment, traders are barred from engaging in High-Frequency Trading (HFT) strategies. This includes holding trades for under 8 seconds, increasing position sizes after a loss (commonly known as HFT Martingale), or using grid trading tactics. Additionally, scalping—defined as executing trades for 2 points or less in over 25% of your trades—is strictly prohibited. Any violation of these guidelines may lead to account termination, as these rules are designed to promote trading stability and discourage actions that could undermine it.<br /><br />
    
          <strong>Performance Account Agreement Compliance:</strong> Traders are required to sign the Performance Account Agreement before commencing any trading activities on their Performance Account. Trading without signing this agreement constitutes non-compliance and may result in immediate account termination. This rule ensures all traders acknowledge and adhere to the terms and conditions governing their accounts. Please ensure the agreement is completed to avoid any disruptions to your account.<br /><br />
    
          Additionally, if any trading activities are detected that defy Humble Futures Funding’s risk tolerance or involve prohibited practices, Humble Futures Funding reserves the right to terminate the trader’s account. For more detailed information, please refer to the <strong>Humble Futures Funding Performance Account Agreement.</strong>
        </p>
      </FaqBoxContent>
    </FaqBox>
  
  

  <FaqBox onClick={() => toggleBox('rule')}>
    <FaqBoxTitle>Prohibited Trading Practices</FaqBoxTitle>
    <FaqBoxContent isOpen={openBox === 'rule'}>
      <p>
        <strong>Humble Futures Funding</strong>, like many other reputable trading firms, enforces strict rules to ensure fair and responsible trading. These include prohibitions on:
        <br /><br />
        <ul>
          <li><strong>Grid Trading:</strong> Using a grid strategy that involves placing buy and sell orders at set intervals to capture market movements is not allowed.</li>
          <li><strong>Martingale Strategies:</strong> Increasing trade sizes after a loss in hopes of recovering quickly (known as the Martingale strategy) is strictly forbidden.</li>
          <li><strong>High-Frequency Trading (HFT):</strong> Engaging in HFT practices, such as holding trades for less than 8 seconds or executing an excessive number of trades in a short period, is not permitted.</li>
          <li><strong>Scalping Restrictions:</strong> Making trades below the tick thresholds listed below in more than 25% of your trades is prohibited to discourage overly speculative behavior:
            <ul>
              <li><strong>E-mini NASDAQ-100 (NQ):</strong> Minimum of 12 ticks ($60) per trade</li>
              <li><strong>Crude Oil (CL):</strong> Minimum of 6 ticks ($60) per trade</li>
              <li><strong>Gold (GC):</strong> Minimum of 6 ticks ($60) per trade</li>
              <li><strong>E-mini S&P 500 (ES):</strong> Minimum of 4 ticks ($50) per trade</li>
              <li><strong>Euro FX (6E):</strong> Minimum of 8 ticks ($50) per trade</li>
              <li><strong>Mini Dow (YM):</strong> Minimum of 6 ticks ($30) per trade</li>
              <li><strong>Micro NASDAQ-100 (MNQ):</strong> Minimum of 12 ticks ($60) per trade</li>
              <li><strong>Micro S&P 500 (MES):</strong> Minimum of 4 ticks ($20) per trade</li>
              <li><strong>Russell 2000 (RTY):</strong> Minimum of 10 ticks ($50) per trade</li>
            </ul>
          </li>
          <li><strong>Unauthorized Trading Algorithms:</strong> Use of automated trading bots or algorithms not approved by Humble Futures Funding is strictly prohibited.</li>
          <li><strong>Inactivity Rule:</strong> If any account—Evaluation, Standard, or Performance Account (PA)—is left without any trading activity for <strong>30 or more calendar days</strong>, the account may be subject to review and termination. This rule ensures that all accounts maintain active and responsible trading practices.</li>
        </ul>
        <br />
        These rules are in place to promote disciplined trading, reduce unnecessary risk, and align with industry standards for responsible trading practices. Violations of these practices may lead to immediate account termination.
      </p>
    </FaqBoxContent>
  </FaqBox>


  
  
        <FaqBox onClick={() => toggleBox('type')}>
        <FaqBoxTitle>What types of futures contracts are available for trading?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'type'}>
          <p>
          <strong>Available Futures Contracts:</strong><br />
          At Humble Futures Funding, traders have access to a wide range of futures contracts across various markets, similar to what other leading proprietary trading firms offer. The available futures contracts typically include:
          <br /><br />
          <ul>
            <li><strong>Equity Index Futures:</strong> Trade popular indices like the S&P 500 (ES), NASDAQ 100 (NQ), Dow Jones (YM), and Russell 2000 (RTY).</li>
            <li><strong>Commodity Futures:</strong> Access markets such as Crude Oil (CL), Gold (GC), Silver (SI), and Natural Gas (NG).</li>
            <li><strong>Currency Futures:</strong> Engage in currency futures trading with contracts like Euro (6E), British Pound (6B), Japanese Yen (6J), and Australian Dollar (6A).</li>
            <li><strong>Interest Rate Futures:</strong> Trade U.S. Treasury bonds and notes, such as the 10-Year Note (ZN) and 30-Year Bond (ZB).</li>
            <li><strong>Agricultural Futures:</strong> Participate in markets for commodities like Corn (ZC), Soybeans (ZS), and Wheat (ZW).</li>
            <li><strong>Metal Futures:</strong> Access contracts for metals like Copper (HG) in addition to precious metals like Gold and Silver.</li>
            <li><strong>Energy Futures:</strong> Trade key energy markets including Crude Oil (CL) and Natural Gas (NG).</li>
          </ul>
          <br />
          These futures contracts provide diverse opportunities for traders to engage in various market segments, offering both liquidity and volatility suitable for a range of trading strategies. Keep in mind that while these contracts are common across many prop trading firms, the specific offerings and conditions may vary depending on the firm’s policies and agreements with exchanges.
          </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('futures')}>
        <FaqBoxTitle>Can I trade during news events?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'futures'}>
            <p>
            Trading during major news events is allowed; however, we recommend that traders exercise caution due to the high volatility these events can cause. Make sure your risk management strategies are robust to handle sudden market movements.
            </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('lev')}>
        <FaqBoxTitle>Can traders use leverage, and what are the associated risks?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'lev'}>
            <p>
            Humble Futures Funding does not permit the use of leverage in trading. We believe that trading without leverage encourages more responsible risk management and helps traders avoid the potential for significant losses that can occur with leveraged positions. Leverage can amplify both gains and losses, often leading to high-risk trading behaviors. By trading without leverage, our traders are encouraged to focus on long-term, sustainable strategies that prioritize capital preservation and steady growth, aligning with our commitment to fostering disciplined trading practices.            </p>
        </FaqBoxContent>
        </FaqBox>

        <FaqBox onClick={() => toggleBox('payout')}>
          <FaqBoxTitle>What are the payout options, and are there any incentives?</FaqBoxTitle>
          <FaqBoxContent isOpen={openBox === 'payout'}>
            <p>
              <strong>Default Payout Option:</strong><br />
              By default, Humble Futures Funding provides <strong>instant payout options</strong> for traders upon request. Once traders qualify for a payout, an email will be sent to verify and validate their request, and the payout will be processed immediately.
              <br /><br />
              <strong>Payout Spread Options:</strong><br />
              Traders also have the option to choose a payout spread to break their payout into smaller, monthly installments:
              <ul>
                <li>
                  <strong>Standard Performance Account:</strong> Opt for a 3-month payout spread. For example, a $1500 payout would be divided into $500 monthly payments over 3 months.
                </li>
                <li>
                  <strong>Premium Performance Account:</strong> Opt for a 2-month payout spread. For example, a $1500 payout would be divided into $750 monthly payments over 2 months.
                </li>
              </ul>
              <br />
              <strong>Incentives for Choosing Payout Spreads:</strong><br />
              Traders who select a payout spread option will receive the following incentives:
              <ul>
                <li>
                  <strong>Standard Performance Account:</strong> 3 free evaluation account resets.
                </li>
                <li>
                  <strong>Premium Performance Account:</strong> 2 free evaluation account resets and 1 free performance account reset.
                </li>
              </ul>
            </p>
          </FaqBoxContent>
        </FaqBox>
      

        
        <FaqBox onClick={() => toggleBox('restrictedCountries')}>
        <FaqBoxTitle>Which countries are restricted from receiving payouts or services from Humble Futures Funding?</FaqBoxTitle>
        <FaqBoxContent isOpen={openBox === 'restrictedCountries'}>
          <p>
            Due to international regulations, compliance requirements, and local laws, our futures prop firm cannot provide services or make payouts to clients in the following countries:
            <br /><br />
            <strong>Countries under Sanctions:</strong>
            <ul>
              <li>Iran</li>
              <li>North Korea</li>
              <li>Syria</li>
              <li>Cuba</li>
              <li>Crimea Region of Ukraine</li>
              <li>Belarus</li>
              <li>Russia (varies by specific regions and entities)</li>
            </ul>
            <strong>High-Risk Jurisdictions for AML/KYC:</strong>
            <ul>
              <li>Afghanistan</li>
              <li>Myanmar (Burma)</li>
              <li>Yemen</li>
              <li>Iraq</li>
              <li>South Sudan</li>
              <li>Somalia</li>
            </ul>
            <strong>Countries with Local Restrictions on Futures Trading:</strong>
            <ul>
              <li>China (restrictions vary based on specific financial products and firms)</li>
              <li>Saudi Arabia</li>
            </ul>
            <strong>Countries with High Regulatory Risk:</strong>
            <ul>
              <li>Nigeria</li>
              <li>Zimbabwe</li>
              <li>Venezuela</li>
              <li>Lebanon</li>
              <li>Sudan</li>
            </ul>
            <strong>Countries with Financial Transaction Restrictions:</strong>
            <ul>
              <li>Libya</li>
              <li>Democratic Republic of the Congo</li>
              <li>Central African Republic</li>
            </ul>
            <br />
            This list is subject to change based on updates to international regulations and compliance requirements. We recommend checking with our compliance team for the most current information.
          </p>
        </FaqBoxContent>
      </FaqBox>
      

      </FaqBoxesContainer>
    </FaqPageContainer>
  );
};

export default FaqPage;
