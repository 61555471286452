import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import Logo from '../../images/newhf.png'
import { FaDiscord, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FooterContainer,
FooterWrap,
FooterLinksContainer,
FooterLinksWrapper,
FooterLinkItems,
FooterLinkTitle,
FooterLink,
SocialMedia,
SocialMediaWrap,
SocialLogo, 
WebsiteRights,
SocialIcons,
SocialIconLink,
FooterAnchor,
StyledScrollLink,
StyledLink
} from './FooterElements';


const Footer = () => {
    const toggleHome = () => {
      scroll.scrollToTop();
    };

  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>About Us</FooterLinkTitle>
                        <FooterLink to="/signup/signin">Sign Up</FooterLink>
                        <StyledLink to="/faqq">FAQ</StyledLink>
                        <StyledScrollLink to="plans" smooth={true} duration={500}>Account Size's</StyledScrollLink>
                        <StyledLink to="/toss">Terms of Service</StyledLink>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                            <FooterLinkTitle>Social Media</FooterLinkTitle>
                            <FooterAnchor href="https://discord.gg/ZvDDTuzFus" target="_blank" aria-label="Discord">
                                Discord
                            </FooterAnchor>
                            <FooterAnchor href="https://www.instagram.com/humblefuturesfunding/" target="_blank" aria-label="Instagram">
                                Instagram
                            </FooterAnchor>
                            <FooterAnchor href="https://www.tiktok.com/@humblefuturesfunding" target="_blank" aria-label="TikTok">
                                TikTok
                            </FooterAnchor>
                            <FooterAnchor href="https://www.youtube.com/@HumbleFuturesFunding" target="_blank" aria-label="Youtube">
                                Youtube 
                            </FooterAnchor>
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        <img src={Logo} alt="Humble Funding" style={{ maxWidth: '160%', height: 'auto' }}></img>
                    </SocialLogo>
                    <WebsiteRights>Humble Futures Funding © {new Date().getFullYear()} All Rights Reserved.</WebsiteRights>
                    <SocialIcons>
                        <SocialIconLink href="https://discord.gg/ZvDDTuzFus" target="_blank" aria-label="Discord">
                            <FaDiscord />
                        </SocialIconLink>
                        <SocialIconLink href="https://www.instagram.com/humblefuturesfunding/" target="_blank" aria-label="Instagram">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="https://www.youtube.com/@HumbleFuturesFunding" target="_blank" aria-label="Youtube">
                            <FaYoutube />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer;
