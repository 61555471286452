import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { SaleProvider } from './SaleContext';
import GoogleAds from './pages/GoogleAdsHelmet'; // Import your GoogleAdsHelmet component
import Home from './pages';
//import LoginPage from './pages/Login';
import OrderPage from './pages/orderPage';
import Dashboard from './pages/Dashboard';
import FaqPage from './pages/FaqPage';
import ForgotPass from './pages/ForgotPass';
import RQ from './pages/RQ';
import Register from './pages/Register';
import BillingTOC from './pages/billingTOC'; 
import IndustryREG from './pages/industryREG';
import PayoutGD from './pages/PayoutGD';
import TOS from './pages/TOS';
import AffiliatePortal from './pages/AP';


function App() {
  return (
    <Router>
      <GoogleAds /> {/* Include Google Ads Script across all pages */}
      <SaleProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup/signin" element={<OrderPage />} />
          <Route path="/faqq" element={<FaqPage />} />
          <Route path="/request-payout" element={<RQ />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPass />} />
          <Route path="/billing-toc" element={<BillingTOC />} />
          <Route path="/industry-reg" element={<IndustryREG />} />
          <Route path="/payout-gd" element={<PayoutGD />} />
          <Route path="/toss" element={<TOS />} />
          <Route path="/affiliate-portal" element={<AffiliatePortal />} />
        </Routes>
      </SaleProvider>
    </Router>
  );
}

export default App;
