import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import AboutSection from '../components/InfoSection/Aboutindex';
import Services from '../components/Services';
import ChartSection from '../components/ChartSection';
import Footer from '../components/Footer';
import styled from 'styled-components';
import ProcessSection from '../components/InfoSection';

const PageWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const GradientBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, #00bfff, #87CEEB, #ffffff, #B0C4DE);
  background-size: 200% 200%;
  animation: spiralAnimation 10s linear infinite;
  z-index: -1;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`;

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Navbar toggle={toggle} />
      <GradientBackground />
      <PageWrapper>
        <HeroSection />
        <AboutSection/>
        <div id="process">
        <ProcessSection />
      </div>
        <Services />
        <ChartSection />
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Home;
