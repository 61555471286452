import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
    background: ${({ scrollNav }) => (scrollNav ? 'rgba(255, 255, 255, 0.7)' : 'transparent')};
    backdrop-filter: ${({ scrollNav }) => (scrollNav ? 'blur(10px)' : 'none')};
    -webkit-backdrop-filter: ${({ scrollNav }) => (scrollNav ? 'blur(10px)' : 'none')};
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: fixed; /* Use fixed position to stick to the top */
    top: 0;
    width: 100%; /* Ensure full width */
    z-index: 10;
    padding-top: 20px; /* Add top padding for mobile */
    padding-bottom: 20px; /* Add bottom padding for mobile */
    font-family: 'Roboto', sans-serif; /* Apply the Roboto font */

    @media screen and (max-width: 768px) {
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        height: auto; /* Adjust height for mobile */
    }

    @media screen and (min-width: 1024px) {
        height: auto;
        flex-direction: column;
    }
`;

export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1100px;

    @media screen and (max-width: 960px) {
        flex-direction: column; // Stack elements vertically
        height: auto;
    }
`;

export const NavLogo = styled(LinkR)`
    color: #fff;
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 24px;
    margin-left: 24px;
    font-weight: bold;
    text-decoration: none;

    img {
        max-height: 100%;
        width: auto;
    }

    @media screen and (max-width: 767px) {
        img {
            max-height: 100px; // Adjust for phone
        }
    }

    @media screen and (min-width: 768px) and (max-width: 1023px) {
        img {
            max-height: 100px; // Adjust for tablet
        }
    }

    @media screen and (min-width: 1024px) {
        img {
            max-height: 150px; // Adjust for desktop
        }
    }
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;

    @media screen and (max-width: 768px) {
        display: none; // Hide menu items on mobile
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; /* Allow items to wrap */
    padding: 10px 20px; /* Adjust as needed */
    border: 2px solid rgba(255, 255, 255, 0.2); /* Border style */
    border-radius: 30px; /* Rounded corners */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    width: auto; /* Adjust width as needed */
    margin: 0 auto; /* Center the container */

    @media screen and (max-width: 768px) {
        display: none; /* Hide GroupContainer on mobile */
    }
`;

export const NavItem = styled.li`
    margin: 0 10px; /* Adjust spacing as needed */
`;

export const NavLinks = styled(LinkS)`
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 10px;
    height: 100%;
    cursor: pointer;
    position: relative;
    font-family: 'Roboto', sans-serif; /* Apply the Roboto font */
    font-weight: 500; /* Slightly bolder font weight */
    letter-spacing: 0.05em; /* Slightly increased letter spacing */
    text-transform: uppercase; /* Uppercase text */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);

    &.active {
        color: #fff;
    }

    &:hover {
        color:  #00bfff; /* Change hover color to grey */
    }

    &:hover::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -5px; /* Position below the text */
        left: 0;
        background-color:  #00bfff; /* Change underline color to grey */
        visibility: visible;
        transform: scaleX(1);
        transition: all 0.3s ease-in-out;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -5px; /* Position below the text */
        left: 0;
        background-color: #fff;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }
`;

export const NavBtn = styled.nav`
    display: flex;
    align-items: center;
    margin-left: 10px;

    @media screen and (max-width: 768px) {
        width: 100%; /* Ensure full width */
        justify-content: center; /* Center the button */
    }
`;

export const NavBtnLink = styled(LinkR)`
    border-radius: 4px; /* Slightly rounded edges */
    background: black;
    white-space: nowrap;
    padding: 20px 20px;
    color: #fff; /* White text color */
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease; /* Smooth transition on hover */
    text-decoration: none;
    font-family: 'Roboto', sans-serif; /* Apply the Roboto font */
    font-weight: 700; /* Bold font weight */
    letter-spacing: 0.05em; /* Slightly increased letter spacing */
    text-transform: uppercase; /* Uppercase text */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle text shadow */

    &:hover {
        background: linear-gradient(135deg, #00bfff, black); /* Reverse gradient on hover */
        color: #f1f1f1; /* Slightly off-white text color on hover */
    }
`;

