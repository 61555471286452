import React, { createContext, useState, useContext } from 'react';

// Create a context for the sale
const SaleContext = createContext();

// Custom hook to use the sale context
export const useSale = () => useContext(SaleContext);

// Provider component that will wrap your app components
export const SaleProvider = ({ children }) => {
  const [currentSale, setCurrentSale] = useState({
    discount: 90, // Default to the launch sale discount
    // duration: 15 * 1000, // 15 seconds for the launch sale duration
    duration: 14*24*60*60*1000,
    active: true, // Indicates if the sale is active
  });

  // The value that will be shared across components
  const saleValue = {
    currentSale,
    setCurrentSale
  };

  return (
    <SaleContext.Provider value={saleValue}>
      {children}
    </SaleContext.Provider>
  );
};
