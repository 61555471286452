import styled from 'styled-components';

const gradientText = `
  background: linear-gradient(90deg, black, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

export const ChartContainer = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
`;

export const ChartTitle = styled.h2`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 24px;
`;

export const ChartImage = styled.img`
  max-width: 600px;
  width: 100%;
  height: auto;
  border: none;
  align-self: center;
  margin-top: 20px;
`;

export const PoweredByContainer = styled.div`
  text-align: center;
  margin-top: 120px;
  color: #fff;
`;

export const PoweredByTitle = styled.h2`
  font-size: 2rem;
  ${gradientText}
  margin-bottom: 40px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 120px;
`;

export const Image = styled.img`
  max-width: 100px;
  height: auto;
`;

export const RiskDisclosure = styled.p`
  font-size: 15px;
  margin: 0 20px;
  margin-bottom: 20px;
  color: black;
  text-align: left;
`;


export const PayoutTable = styled.table`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
  background: #007acc; /* Match the responsive card section color */
  color: #fff;
  border: 2px solid #00bfff; /* Add a border to the table */

  @media (max-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 480px) {
    max-width: 100%;
    font-size: 14px;
  }
`;

export const PayoutHeader = styled.thead`
  font-weight: bold;
  background-color: #004080;
`;

export const PayoutRow = styled.tr`
  background-color: ${props => (props.header ? '#004080' : 'transparent')};
`;

export const PayoutCell = styled.td`
  border: 1px solid #fff;
  padding: 8px 16px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 6px 12px;
  }

  @media (max-width: 480px) {
    padding: 4px 8px;
  }
`;

export const PayoutTitle = styled.h1`
  font-size: 2.5rem;
  ${gradientText}
  font-weight: bold;
  margin-bottom: 16px;
  text-align: center;
`;

export const PayoutSubtitle = styled.h2`
  font-size: 2rem;
  ${gradientText}
  margin-bottom: 24px;
  text-align: center;
`;
