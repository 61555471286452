import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const HeroContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 30px; /* Add padding to push down */
    height: 850px;
    position: relative;
    z-index: 1;
    background: transparent; /* Ensure the background is transparent */

    @media screen and (max-width: 768px) {
        height: 500px; 
    }

    @media screen and (max-width: 480px) {
        height: 400px;
        margin-top: 100px;
    }
`;

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: relative;
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

export const HeroTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 600px;
    margin-right: 20px;

    @media screen and (max-width: 768px) {
        align-items: center;
        margin-right: 0;
        margin-top: 1100px;
    }
`;

export const HeroImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.75); /* Semi-transparent black background for better visibility */
    border: 2px solid #007acc;
    border-radius: 25px; /* Curved edges */
    padding: 20px;
    box-shadow: inset 0 0 0 2px #007acc;

     @media screen and (max-width: 768px) {
        margin-top: 200px;
    }
`;

export const HeroH1 = styled.h1`
    font-size: 48px;
    text-align: left; /* Align text to the left */
    text-transform: uppercase;
    color: black; /* Set the default color to black */

    span {
        color: #007acc; /* Apply darker blue color to FUNDING */
    }

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`;

export const HeroP = styled.p`
    margin-top: 12px;
    font-size: 24px;
    text-align: left; /* Align text to the left */
    max-width: 600px;
    color: black; /* Set the color to black */

    span {
        color: #007acc; /* Apply darker blue color to FUNDING */
    }

    @media screen and (max-width: 768px) {
        font-size: 24px;
        text-align: center; /* Center text for smaller screens */
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
        text-align: center; /* Center text for smaller screens */
    }
`;

export const HeroP2 = styled.p`
    margin-top: 12px;
    font-size: 32px;
    font-weight: bold;
    text-align: left; /* Align text to the left */
    color: black; /* Set the default color to black */

    span {
        color: #007acc; /* Apply darker blue color to Ally and Successful Trading */
    }

    @media screen and (max-width: 768px) {
        font-size: 24px;
        text-align: center; /* Center text for smaller screens */
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
        text-align: center; /* Center text for smaller screens */
    }
`;
export const HeroP3 = styled.p`
    margin-top: 10px;
    font-size: 20px;
    text-align: left; /* Align text to the left */
    max-width: 600px;
    color: black; /* Set the color to black */

    @media screen and (max-width: 768px) {
        text-align: center; /* Center text for smaller screens */
    }
`;

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`;

export const StarImage = styled.img`
    width: 120px; /* Adjusted size */
    height: auto;
`;

export const TimerWrapper = styled.div`
    margin-top: 20px;
`;

export const TimeSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5px; /* Add space between sections */
    padding: 0;
`;

export const TimeValue = styled.span`
    color: #fff;
    font-size: 2rem; /* Adjusted size */
    font-weight: bold;
    margin-bottom: 5px; /* Space between the time value and its label */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for better visibility */
`;

export const TimeLabel = styled.span`
    color: #00bfff; /* Color for the labels */
    font-size: 1rem; /* Adjusted size */
    text-transform: uppercase; /* Uppercase label text */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for better visibility */
`;

export const SaleBox = styled.div`
    background: rgba(0, 0, 0, 0.75); /* Semi-transparent black background for better visibility */
    border: 2px solid #007acc;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
    border-radius: 25px; /* Curved edges */
    box-shadow: inset 0 0 0 2px #007acc;

    @media screen and (max-width: 768px) {
        width: auto;
        font-size: 0.8rem;
    }
`;

export const SaleText = styled.div`
  text-align: center; /* This ensures the text aligns to the center */
  width: 100%; /* This ensures the div takes the full width */
  font-size: 1.5rem; /* Adjusted size */
  margin: auto; /* Centers the div in the available space if it's a block element */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow for better visibility */
  /* Add any media queries as necessary for responsiveness */
  @media screen and (max-width: 768px) {
    font-size: 1rem; /* Smaller font size for smaller screens */
  }
`;

export const TimeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
`;

export const InfoText = styled.p`
    color: #fff;
    font-size: 16px; /* Adjusted size */
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
`;

export const Divider = styled.div`
    width: 100%;
    height: 5px;
    background: #007acc;
    margin: 5px 0;
`;

export const PromoCodeText = styled.h2`
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(to right, white, #00bfff); // Customize the gradient colors
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 10px;
`;

