import React from 'react';
import { Helmet } from 'react-helmet';

const GoogleAdsHelmet = () => (
  <>
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11481638291"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11481638291');
        `}
      </script>
    </Helmet>
  </>
);

export default GoogleAdsHelmet;
