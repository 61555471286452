import React, { useState, useEffect } from 'react';
import { SaleBox, SaleText, TimeContainer, TimeSection, TimeValue, TimeLabel } from './HeroElements';

const SaleTimer = () => {
  const [timeLeft, setTimeLeft] = useState(); // Initialize as null to indicate loading state
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    const fetchSaleData = async () => {
      try {
        const response = await fetch('/.netlify/functions/saleTime');
        const data = await response.json();
        console.log(data); // Check the actual structure and values
        if (data && data.timeLeft !== undefined) {
          setTimeLeft(data.timeLeft);
          setDiscount(data.timeLeft === 0 ? 82 : data.discount);
        } else {
          setTimeLeft(0); // Handle case when sale is finished
          setDiscount(0);
        }
      } catch (error) {
        console.error('Error fetching sale data:', error);
        setTimeLeft(0); // Handle error by setting timeLeft to 0
        setDiscount(0);  // Handle error by setting discount to 0
      }
    };
  
    fetchSaleData();
  
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === null) return 0; // Handle null state
        const updatedTime = Math.max(prevTime - 1000, 0);
        if (updatedTime === 0) {
          clearInterval(intervalId);
        }
        return updatedTime;
      });
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, []);
  

  // Helper function to format the time left into days, hours, minutes, and seconds
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24)).toString().padStart(2, '0');
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24).toString().padStart(2, '0');
    const minutes = Math.floor((time / (1000 * 60)) % 60).toString().padStart(2, '0');
    const seconds = Math.floor((time / 1000) % 60).toString().padStart(2, '0');
    return { days, hours, minutes, seconds };
  };

  const { days, hours, minutes, seconds } = timeLeft !== null ? formatTime(timeLeft) : { days: '00', hours: '00', minutes: '00', seconds: '00' };

  return (
    <SaleBox>
      {timeLeft !== null && timeLeft > 0 ? (
        <>
          <SaleText>{discount}% OFF APPLIES TO STANDARD ACCOUNTS</SaleText>
          <TimeContainer>
            <TimeSection>
              <TimeValue>{days}</TimeValue>
              <TimeLabel>Days</TimeLabel>
            </TimeSection>
            <TimeSection>
              <TimeValue>{hours}</TimeValue>
              <TimeLabel>Hrs</TimeLabel>
            </TimeSection>
            <TimeSection>
              <TimeValue>{minutes}</TimeValue>
              <TimeLabel>Min</TimeLabel>
            </TimeSection>
            <TimeSection>
              <TimeValue>{seconds}</TimeValue>
              <TimeLabel>Sec</TimeLabel>
            </TimeSection>
          </TimeContainer>
        </>
      ) : (
        <SaleText>Loading sale data...</SaleText>
      )}
    </SaleBox>
  );
  
};

export default SaleTimer;
