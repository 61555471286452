import React from 'react';
import { ProcessContainer, ProcessWrapper, ProcessSlider, ProcessSlide, ProcessTitle, ProcessDescription, ProcessHeading, ProcessNumber, PathSection, PathTitle, PathDescription, CheckList, CheckItem, ActivationFeesBox, FeesTitle, FeesList, FeesItem, PathsContainer} from './ProcessElements';

const ProcessSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const processSectionData = {
    id: 'process',
    lightBg: false,
    headline: 'OUR PROCESS',
    steps: [
      {
        title: "Choose Evaluation Account Type & Size",
        description: [
          "Complete your registration with Humble Futures Funding. Purchase an evaluation account with the account type and size you prefer. No background check or legal details are required for this step."
        ]
      },
      {
        title: "Download Partner Technologies",
        description: [
          "Download and install R Trader Pro. Afterwards, download your preferred trading platform and sign in using the Rithmic credentials provided by Humble Futures Funding."
        ]
      },
      {
        title: "Qualify For A Performance Account",
        description: [
          "Begin your journey by trading your evaluation account. Upon successfully passing and showcasing your risk management skills, proceed to verification. Once verified, choose between a standard or premium performance account."
        ]
      },
      {
        title: "Become Funded With Humble Futures Funding",
        description: [
          "Trade on your Performance Account and prove your success. Achieve the established profit target and enjoy up to a 100% profit split up till the first $25K, with 90% thereafter. Benefit from swift payouts, 24/7 live support, and the ability to copy trade up to 25 Performance Accounts simultaneously with our streamlined one-step evaluation."
        ]
      }
    ]
  };

  const paths = [
    {
      name: 'STANDARD PATH',
      description: 'The Standard Path involves buying evaluation accounts with activation fees, offering discounts along the way. Once passed, traders choose between Standard or Premium Performance Accounts. It is perfect for those wanting a slower, more confident journey to their first 25K payout.',
      features: [
        'Consistency Rule on Evaluation and Performance Accounts',
        'Revolving Discount on Evaluation Accounts & Activation Fees',
        'Option to Promote to Premium Performance Account',
      ],
      themeColor: 'bronze', // Bronze
      textColor: '#fff',
      iconColor: '#fff',
    },
    {
      name: 'EXPRESS PATH',
      description: 'The Express Path offers traders a fast track from evaluation to performance accounts with a monthly subscription, no activation fees, and one free reset per account (shared from a limited pool). There is no consistency rule for evaluation accounts, but a 30% rule applies to Performance Accounts. This option is ideal for confident traders looking to quickly reach their first 25K payout.',
      features: [
        'No Activation Fees',
        'Consistency Rule Only on Performance Accounts',
        'Shared Pool of Free Resets',
      ],
      themeColor: 'silver', // Silver
      textColor: '#000',
      iconColor: '#000',
    },
    {
      name: 'INSTANT FUNDING PATH',
      description: 'The Instant Funding Path gives traders immediate access to a Performance Account for a one-time fee. With a 30% consistency rule, it is designed for experienced traders who’ve been funded before and can consistently perform, helping them grow with funds from successful payouts.',
      features: [
        'Instant Funding',
        'Consistency Rule on Performance Accounts',
        'One-Time Fee',
      ],
      themeColor: 'gold', // Gold
      textColor: '#4f4f4f',
      iconColor: '#000',
    },
  ];
  

  return (
    <ProcessContainer id={processSectionData.id}>
      <ProcessWrapper>
        <ProcessHeading>{processSectionData.headline}</ProcessHeading>
        <ProcessSlider {...settings}>
          {processSectionData.steps.map((step, index) => (
            <ProcessSlide key={index}>
              <ProcessNumber>{String(index + 1).padStart(2, '0')}</ProcessNumber>
              <ProcessTitle>{step.title}</ProcessTitle>
              <ProcessDescription>
                {step.description.map((desc, itemIndex) => <p key={itemIndex}>{desc}</p>)}
              </ProcessDescription>
            </ProcessSlide>
          ))}
        </ProcessSlider>
        
        <PathsContainer>
        {paths.map((path, index) => (
          <PathSection key={index} themeType={path.themeColor} textColor={path.textColor}>
            <PathTitle textColor={path.textColor}>{path.name}</PathTitle>
            <PathDescription textColor={path.textColor}>{path.description}</PathDescription>
            <CheckList textColor={path.textColor}>
              {path.features.map((feature, i) => (
                <CheckItem key={i} iconColor={path.iconColor}>{feature}</CheckItem>
              ))}
            </CheckList>
          </PathSection>
        ))}
      </PathsContainer>
        
        <ActivationFeesBox>
          <FeesTitle>ONLY FOR THE STANDARD PATH</FeesTitle>
          <FeesList>
            <FeesItem>$79.99 Monthly Activation Fee</FeesItem>
            <FeesItem>$180 Lifetime + One Day Pass Fee (Standard Performance Account)</FeesItem>
            <FeesItem>$210 Lifetime + One Day Pass Fee (Premium Performance Account)</FeesItem>
          </FeesList>
        </ActivationFeesBox>

      </ProcessWrapper>
    </ProcessContainer>
  );
}

export default ProcessSection;
