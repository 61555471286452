import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaEnvelope, FaUser } from 'react-icons/fa';

const moveBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const RQContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg, white, #00bfff);
  background-size: 200% 200%;
  animation: ${moveBackground} 10s linear infinite;
  text-align: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
    height: auto;
    min-height: 100vh;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  color: #007acc;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const InfoCard = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  margin-bottom: 1.5rem;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const InfoHeader = styled.h2`
  font-size: 1.5rem;
  color: #007acc;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const IconWrapper = styled.span`
  font-size: 1.2rem;
  color: #00bfff;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const InfoText = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 90%;
  max-width: 500px;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const InputField = styled.input`
  padding: 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: all 0.3s ease;

  &:focus {
    border-color: #00bfff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 191, 255, 0.5);
  }

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 0.9rem;
  }
`;

const RequestButton = styled.button`
  font-size: 1.25rem;
  padding: 15px;
  border: none;
  border-radius: 5px;
  background-color: #007acc;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #00bfff;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

function RQ() {
  const handleEmail = () => {
    // Opens the default mail client with prefilled subject and body
    window.location.href = `mailto:support@humblefunding.co?subject=Request Payout&body=Please include your details below:%0D%0A%0D%0ARithmic ID: [Your Rithmic ID]%0D%0AFirst Name: [Your First Name]%0D%0ALast Name: [Your Last Name]`;
  };

  return (
    <RQContainer>
      <Heading>Request Your Payout</Heading>

      <InfoCard>
        <InfoHeader>
          <IconWrapper>
            <FaEnvelope />
          </IconWrapper>
          Contact Support
        </InfoHeader>
        <InfoText>Email: support@humblefunding.co</InfoText>
      </InfoCard>

      <InfoCard>
        <InfoHeader>
          <IconWrapper>
            <FaUser />
          </IconWrapper>
          Important Details
        </InfoHeader>
        <InfoText>
          Include your Rithmic User ID, Full Name, and a Government Issued ID Number (LICENSE NUMBER FOR US).
        </InfoText>
        <InfoText>Additional verification questions may be required.</InfoText>
      </InfoCard>

      <FormContainer>
        <InputField type="text" placeholder="Rithmic User ID" />
        <InputField type="text" placeholder="Full Name" />
        <InputField type="text" placeholder="Government Issued ID" />
        <RequestButton onClick={handleEmail}>Send Request</RequestButton>
      </FormContainer>
    </RQContainer>
  );
}

export default RQ;
