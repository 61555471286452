// src/components/ProcessElements.js

import styled, { keyframes } from 'styled-components';
import "slick-carousel/slick/slick.css"; 
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import upwardTrendlineImage from './line.svg'
import bronze from './bronze.png'
import silver from './star.png'
import gold from './gold.png'

const gradientText = `
  background: linear-gradient(90deg, black, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const entranceAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ProcessContainer = styled.div`
  color: #fff;
  padding: 100px 0;
  background: none;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
`;

export const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  margin-bottom: 50px; /* Added margin to separate from the next section */
`;

export const ProcessHeading = styled.h1`
  font-size: 2.5rem;
  ${gradientText}
  text-align: center;
  margin-bottom: 50px;
  padding-top: 30px;
`;


export const ProcessSlider = styled(Slider)`
  width: 80%;
  margin: 0 auto; /* Center the slider within its container */

  .slick-slide {
    display: flex !important; /* Ensure the slider items use flex */
    justify-content: center; /* Center the content horizontally */
    align-items: center;
  }

  .slick-dots li button:before {
    color: white; /* Active dot color */
  }

  .slick-dots li.slick-active button:before {
    color: black; /* Selected dot color */
  }

  .slick-prev:before, .slick-next:before {
    color: black; /* Arrow color */
  }
`;
export const ProcessSlide = styled.div`
  background: #f0f0f0;
  border-radius: 20px;
  padding: 50px; /* Increased padding */
  margin: 0 auto; /* Center each slide within the slider */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: #333;
  opacity: 0;
  animation: ${entranceAnimation} 0.6s ease-out forwards;
  width: 100%;
  max-width: 625px; /* Increased max width */

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 30px; /* Adjusted padding for smaller screens */
  }
`;

export const ProcessTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 22px; /* Increased font size */
  color: #00bfff;
`;

export const ProcessNumber = styled.div`
  font-size: 2rem; /* Increased font size */
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ProcessDescription = styled.p`
  font-size: 16px; /* Increased font size */
  color: #010606;
  line-height: 1.6;
`;

// New styled components for the vertical sections with textured colors

const texturedBackgrounds = {
  bronze: `
    background: linear-gradient(135deg, #cd7f32, #aa6f2f);
    background-image: url(${bronze});
    background-blend-mode: multiply;
    background-size: 500%;
    background-position: center;
    background-repeat: no-repeat;
    color: #ffffff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    border: 2px solid #cd7f32;
  `,
  silver: `
    background: linear-gradient(135deg, #c0c0c0, #8c8c8c);
    background-image: url(${silver});
    background-blend-mode: multiply;
    background-size: 1000%;
    background-position: center;
    background-repeat: no-repeat;
    color: #000000;
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.5);
    border: 2px solid #c0c0c0;
  `,
  gold: `
    background: linear-gradient(135deg, #ffd700, #d4af37);
    background-image: url(${gold});
    background-blend-mode: multiply;
    background-size: 500%;
    background-position: center;
    background-repeat: no-repeat;
    color: #4f4f4f;
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.5);
    border: 2px solid #ffd700;
  `,
};



export const PathsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 1100px;
  margin: 50px auto;
  gap: 20px;
  margin-top: 300px;
  background: url(${upwardTrendlineImage}) no-repeat center;
  background-position: left 50%;
  background-size: cover;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: none;
    padding: 0 10px;
  }
`;

export const PathSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 20px;
  border-radius: 15px;
  ${({ themeType }) => texturedBackgrounds[themeType] || 'background: #f0f0f0;'}
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out;

  /* Adjust the width and height to make bronze the tallest, then silver, then gold */
  width: ${({ themeType }) => 
    themeType === 'bronze' ? '30%' : 
    themeType === 'silver' ? '28%' : 
    '26%'}; /* Adjust widths accordingly */
    
  max-width: ${({ themeType }) => 
    themeType === 'bronze' ? '300px' : 
    themeType === 'silver' ? '280px' : 
    '260px'}; /* Set max widths accordingly */
    
  height: ${({ themeType }) => 
    themeType === 'bronze' ? '100%' : 
    themeType === 'silver' ? '90%' : 
    '80%'}; /* Ensure bronze is the tallest, then silver, then gold */
  
  text-align: left;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 0;
  }
`;


export const PathTitle = styled.h2`
  font-size: 1.8rem;
  color: ${({ textColor }) => textColor || '#333'};
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: bold;
`;

export const PathDescription = styled.p`
  font-size: 1rem;
  color: ${({ textColor }) => textColor || '#333'};
  line-height: 1.6;
  margin-bottom: 15px;
`;

export const CheckList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 15px;
  color: ${({ textColor }) => textColor || '#333'};
`;

export const CheckItem = styled.li`
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold; 

  &:before {
    content: '✓'; /* Updated check mark symbol */
    color: #00bfff; /* Set to the specific blue color */
    font-size: 1.2rem; /* Adjust the size if needed */
  }
`;

export const ActivationFeesBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  margin-top: 20px;
  background-color: #ffebcc; /* Light orange or similar color for emphasis */
  border: 2px solid #ffb74d; /* Orange border to match */
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%; /* Span full width of the section */
  max-width: 1100px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const FeesTitle = styled.h3`
  font-size: 1.5rem;
  color: black;
  margin-bottom: 10px;
`;

export const FeesList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex; /* Display list items in a row */
  justify-content: space-around; /* Add space between the items */
  width: 100%; /* Make sure the list spans the full width */

  @media screen and (max-width: 768px) {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: center; /* Center items on smaller screens */
  }
`;

export const FeesItem = styled.li`
  font-size: 1rem;
  margin: 0 10px; /* Add horizontal margin for spacing between items */
  color: black;

  @media screen and (max-width: 768px) {
    margin: 5px 0; /* Adjust margin for mobile screens */
    text-align: center;
  }
`;
