// src/components/AboutElements.js

import styled, { keyframes } from "styled-components";

const gradientText = `
  background: linear-gradient(90deg, black, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const entranceAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const floatingAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const InfoContainer = styled.div`
  color: #fff;
  padding: 100px 0;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
    margin-top: 900px;
  }


`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  padding-top: 200px;
`;

export const TopLine = styled.h2`
  font-size: 1.5rem;
  ${gradientText}
  text-align: center;
  margin-bottom: 20px;
`;

export const Heading = styled.h1`
  font-size: 2.5rem;
  ${gradientText}
  text-align: center;
  margin-bottom: 50px;
  padding-top: 30px;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-auto-rows: masonry;
  gap: 20px;
  width: 100%;
  margin-top: 50px; 
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  color: #333;
  opacity: 0; /* Start with opacity 0 */
  animation: ${entranceAnimation} 0.6s ease-out forwards, ${floatingAnimation} 3s ease-in-out infinite;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const CardIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
`;

export const CardTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 18px;
  ${gradientText}
`;

export const CardContent = styled.div`
  font-size: 14px;
  color: #010606;
  line-height: 1.6;
`;
