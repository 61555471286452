import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css'; // Assuming you have a CSS file for styling

const Dashboard = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        username: '',
        promoCode: '',
        redemptions: 0, // Assuming you will also fetch this
        totalCommissions: 0 // Assuming you will also fetch this
    });

    useEffect(() => {
      fetch('/api/userInfo', {
          method: 'GET',
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
          },
      })
      .then(response => {
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
      })
      .then(data => {
          if (data) {
              setUserInfo({
                  username: data.username,
                  promoCode: data.promo_code,
              });
          }
      })
      .catch(error => {
          console.error('Error fetching user data:', error);
          // Optionally handle user feedback here
      });
  }, []);
  

    const handleLogout = () => {
        localStorage.removeItem('token'); // Assuming JWT token stored in localStorage
        navigate('/signup/signin');
    };

    return (
        <div className="dashboard">
            <h1>Welcome, {userInfo.username || 'Loading...'}</h1>
            <div className="info-card">
                <h2>Your Promo Code: {userInfo.promoCode}</h2>
                <p>Redemptions: </p>
                <p>Total Commissions: </p>
            </div>
            <button onClick={handleLogout}>Logout</button>
        </div>
    );
};

export default Dashboard;