import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 40px 20px;
  max-width: 800px;
  margin: auto;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #00bfff;
`;

const GradientTitle = styled.h1`
  font-size: 32px;
  background: linear-gradient(90deg, black, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  text-align: center;
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  color: #00bfff;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const Paragraph = styled.p`
  font-size: 16px;
  color: #666;
  line-height: 1.6;
  margin: 10px 0;
`;

const BillingTerms = () => {
  return (
    <Container>
      <GradientTitle>Cancellation & Refund Policy</GradientTitle>

      <SectionTitle>I. Refund Policy</SectionTitle>
      <Paragraph>
        <strong>No Refunds Policy:</strong> Humble Futures Funding does not offer refunds under normal circumstances. Refunds are only considered if it is determined that a Performance Account has been following improper risk mitigation strategies. In such cases, a refund may be issued, but the trader will be disqualified from qualifying for a payout.
      </Paragraph>
      <Paragraph>
        <strong>Request:</strong> If a trader believes their Performance Account qualifies for a refund due to improper risk mitigation, they must submit a detailed request to <a href="mailto:support@humblefunding.co" style={{ color: '#00bfff' }}>support@humblefunding.co</a> for review.
      </Paragraph>
      <Paragraph>
        <strong>Review Process:</strong> Humble Futures Funding will conduct a thorough review of the trading activities associated with the Performance Account to determine if improper risk mitigation occurred. The decision of Humble Futures Funding will be final.
      </Paragraph>
      <Paragraph>
        <strong>Outcome:</strong> If the refund request is approved, the trader will receive a refund via the original payment method. However, the account will be disqualified from any future payouts, and the trader will be ineligible for future participation in the Performance Account program.
      </Paragraph>
      
      <SectionTitle>II. Non-Refundable Circumstances</SectionTitle>
      <Paragraph>
        <strong>General Non-Refundability:</strong> Accounts that do not fall under the specific conditions mentioned in the Refund Policy section, such as those that have engaged in trading activities, placing trades, opening positions, or utilizing market data, are not eligible for a refund under any circumstances.
      </Paragraph>

      <SectionTitle>III. Account Cancellation</SectionTitle>
      <Paragraph>
        <strong>Request for Cancellation:</strong> Traders may request account cancellation by emailing <a href="mailto:support@humblefunding.co" style={{ color: '#00bfff' }}>support@humblefunding.co</a>.
      </Paragraph>
      <Paragraph>
        <strong>Effect of Cancellation:</strong> Upon cancellation, the trader will forfeit access to Humble Funding services and platforms, and no refund shall be provided for any fees or costs previously incurred.
      </Paragraph>

      <SectionTitle>IV. Termination by Humble Funding</SectionTitle>
      <Paragraph>
        <strong>Right to Terminate:</strong> Humble Funding reserves the right to terminate agreements and deactivate accounts in instances of contract breaches or violations of the General Terms and Conditions (GTC).
      </Paragraph>
      <Paragraph>
        <strong>Consequences of Termination:</strong> Termination may result in account closure, forfeiture of all funds and balances, and potential banning from Humble Futures Funding services.
      </Paragraph>

      <SectionTitle>V. Legal and Jurisdictional Considerations</SectionTitle>
      <Paragraph>
        <strong>Governing Law:</strong> Any actions to enforce the User Agreement shall be governed by and construed in accordance with the laws of Michigan.
      </Paragraph>
      <Paragraph>
        <strong>Jurisdiction:</strong> Legal actions will be brought in the federal or state courts located in Michigan, and traders consent to the personal jurisdiction of such courts.
      </Paragraph>

      <SectionTitle>VI. Out-of-Court Settlements</SectionTitle>
      <Paragraph>
        <strong>Dispute Resolution:</strong> Humble Futures Funding is dedicated to resolving disputes amicably and efficiently. Traders are encouraged to communicate complaints or suggestions directly to Humble Futures Funding via the contact details provided in GTC clause 11.2.
      </Paragraph>

      <SectionTitle>VII. Dispute Resolution and Binding Arbitration</SectionTitle>
      <Paragraph>
        <strong>Negotiation:</strong> In the event of disputes regarding payments made for our services, both parties agree to initially attempt to resolve the matter through negotiation.
      </Paragraph>
      <Paragraph>
        <strong>Binding Arbitration:</strong> If a resolution cannot be reached through negotiation, the parties agree to submit the dispute to binding arbitration in accordance with the rules of the American Arbitration Association.
      </Paragraph>
      <Paragraph>
        <strong>Waiver of Dispute Rights:</strong> By utilizing our services and agreeing to these terms, the TRADER waives all rights to dispute any payments made for our services. This clause is established to prevent misuse of the dispute resolution process and to protect the interests of both parties.
      </Paragraph>
    </Container>
  );
};

export default BillingTerms;
