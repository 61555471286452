import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Modal from './Modal'; // Ensure you import the Modal component
import BillingTOC from './billingTOC'; 
import IndustryReg from './industryREG';
import PayoutGD from './PayoutGD';
import TOS from './TOS';
import FAQ from './FaqPage';
import COM from './Commisions';

// Keyframes for the gradient background animation
const moveBackground = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const DiscountCodeContainer = styled.div`
  margin-bottom: 40px; /* Spacing between the discount codes and the rest */
  text-align: center;
`;

const DiscountCodeText = styled.h1`
  font-size: 1.1rem; /* Large font size for prominence */
  background: linear-gradient(90deg, white, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  white-space: nowrap; /* Ensures the text stays on the same line */

  @media (max-width: 768px) {
    font-size: 0.8rem; /* Adjust font size for tablet and smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.5rem; /* Adjust font size for mobile devices */
  }
`;

const DiscountCodeTextWithMargin = styled(DiscountCodeText)`
  margin-bottom: 20px; /* Adds space between the two discount codes */
`;

// LoadingText component
export const LoadingText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

// LoadingIcon component
export const LoadingIcon = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #00bfff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 2s linear infinite;
`;

// CheckboxContainer component
export const CheckboxContainer = styled.label`
  margin: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(128, 128, 128, 0.2);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 500px;
`;

// Checkbox component
export const Checkbox = styled.input`
  margin-right: 10px;
`;

// StyledLink component
export const StyledLink = styled.a`
  color: #80e5ff;
  text-decoration: underline;
  font-size: 16px;
  transition: color 0.3s ease;

  &:hover {
    color: #00bfff;
    text-decoration: underline;
  }
`;

// StyledRouterLink component
export const StyledRouterLink = styled(RouterLink)`
  color: #80e5ff;
  text-decoration: underline;
  font-size: 16px;
  transition: color 0.3s ease;

  &:hover {
    color: #00bfff;
    text-decoration: underline;
  }
`;

// AffiliateLink component
export const AffiliateLink = styled.a`
  color: #80e5ff;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
`;

const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  background: linear-gradient(90deg, black, #00bfff);
  background-size: 200% 200%;
  animation: ${moveBackground} 10s linear infinite;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
`;

const LeftContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;

  @media (max-width: 768px) {
    position: static;
    width: 100%;
  }
`;

const RightContainer = styled.div`
  margin-left: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const Tab = styled.button`
  background: none;
  border: none;
  padding: 10px 20px;
  font-size: 18px; 
  font-weight: 700;
  color: ${({ active, children }) =>
    active && children === 'Standard' ? '#cd7f32' : // Bronze color for Standard
    active && children === 'Express' ? '#d3d3d3' : // Silver for Express
    active && children === 'Instant Funding' ? '#ffd700' : 
    active && children === 'Special Offers' ? '#80e5ff' : '#fff'}; // Original color for Special Offers
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: ${({ active }) => (active ? '4px solid' : 'none')};
  border-color: ${({ children }) =>
    children === 'Standard' ? '#cd7f32' : // Bronze border for Standard
    children === 'Express' ? '#d3d3d3' : 
    children === 'Instant Funding' ? '#ffd700' : 
    children === 'Special Offers' ? '#00bfff' : '#80e5ff'}; // Original border color for Special Offers

  &:hover {
    color: ${({ children }) =>
      children === 'Standard' ? '#cd7f32' : // Bronze hover for Standard
      children === 'Express' ? '#d3d3d3' : 
      children === 'Instant Funding' ? '#ffd700' : 
      children === 'Special Offers' ? '#00bfff' : '#80e5ff'}; // Original hover color for Special Offers
  }
`;


const Section = styled.div`
  width: 100%;
  max-width: 800px;
  text-align: center;
`;

const PaymentBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 20px;
`;

const SubHeading = styled.h3`
  font-size: 22px;
  color: #80e5ff;
  margin-bottom: 10px;
`;

const ProductDescription = styled.p`
  font-size: 16px;
  margin: 10px 0;
  color: #e0e0e0;
`;

const PriceTag = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  text-align: center;
`;

const Button = styled.a`
  display: block;
  background-color: ${({ type }) => type === 'standard' ? '#cd7f32' : type === 'express' ? '#d3d3d3' :  '#ffd700'};
  color: #fff;
  padding: 15px 25px;
  margin: 10px auto;
  text-decoration: none;
  border-radius: 25px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  opacity: ${props => props.disabled ? '0.5' : '1'};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #00bfff;
    color: #fff;
  }
`;

const PaymentPage = () => {
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIndustryRegModalOpen, setIsIndustryRegModalOpen] = useState(false);
  const [isPayoutGDOpen, setIsPayoutGDOpen] = useState(false);
  const [isTOSOpen, setIsTOSOpen] = useState(false);
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [isCOMOpen, setIsCOMOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('standard');

  const allChecked = check1 && check2 && check3 && check4;

  const standardAccounts = [ 
    { label: '25K FULL', price: '$159/MONTH', href: 'https://buy.stripe.com/7sI4ke5ff8Lp2sMbII', bonus: 50, description: 'Gain access to a $25,000 trading account with an additional $50 kickstart.' },
    { label: '50K FULL', price: '$181/MONTH', href: 'https://buy.stripe.com/28o8Au3777HlgjC001', bonus: 50, description: 'Gain access to a $50,000 trading account with an additional $50 kickstart.' },
    { label: '75K FULL', price: '$200/MONTH', href: 'https://buy.stripe.com/3cs3ga6jj8Lpebu146', bonus: 50, description: 'Gain access to a $75,000 trading account with an additional $50 kickstart.' },
    { label: '100K FULL', price: '$220/MONTH', href: 'https://buy.stripe.com/14k2c6gXX3r59Ve3cf', bonus: 100, description: 'Gain access to a $100,000 trading account with an additional $100 kickstart.' },
    { label: '150K FULL', price: '$310/MONTH', href: 'https://buy.stripe.com/aEUdUOazzd1F8Ra28c', bonus: 100, description: 'Gain access to a $150,000 trading account with an additional $100 kickstart.' },
    { label: '300K FULL', price: '$654/MONTH', href: 'https://buy.stripe.com/9AQ6sm9vvf9N8RafZ3', bonus: 150, description: 'Gain access to a $300,000 trading account with an additional $150 kickstart.' },
  ];

  const expressAccounts = [ 
    { label: '25K EXPRESS', price: '$65/MONTH', href: 'https://buy.stripe.com/00g3gafTT8Lpebu28l', description: 'Express path for a $25,000 trading account with no activation fee and faster payout options.' },
    { label: '50K EXPRESS', price: '$80/MONTH', href: 'https://buy.stripe.com/fZe8Auazz4v95EYfZa', description: 'Express path for a $50,000 trading account with no activation fee and faster payout options.' },
    { label: '150K EXPRESS', price: '$210/MONTH', href: 'https://buy.stripe.com/fZe8AudLL8LpgjC7sG', description: 'Express path for a $150,000 trading account with no activation fee and faster payout options.' },
  ];

  const instantFundingAccounts = [
    { label: '25K INSTANT', price: '$210', href: 'https://buy.stripe.com/4gw6sm9vvaTx7N6aEV', description: 'Access to $25,000 trading account instantly with one-time fee. Includes trailing drawdown and 8 minimum trading days.' },
    { label: '50K INSTANT', price: '$500', href: 'https://buy.stripe.com/3cs8AubDDaTxebu4gw', description: 'Access to $50,000 trading account instantly with one-time fee. Includes trailing drawdown and 8 minimum trading days.' },
    { label: '150K INSTANT', price: '$700', href: 'https://buy.stripe.com/bIY6smePP2n19Ve7sH', description: 'Access to $150,000 trading account instantly with one-time fee. Includes trailing drawdown and 8 minimum trading days.' },
  ];

  const specialOffers = [ 
    { label: 'STANDARD PERFORMANCE ACCOUNT (MONTHLY ACTIVATION FEE)', price: '$80/MONTH', href: 'https://buy.stripe.com/fZe7wq0YZf9Nffy006', description: `To activate the Standard Performance Account, a monthly fee is required. To be eligible for this option, traders must trade for at least 6 days after reaching the profit target.` },
    { label: 'STANDARD PERFORMANCE ACCOUNT (LIFETIME FEE + ONE DAY PASS)', price: '$180 ONE TIME FEE', href: 'https://buy.stripe.com/14k6smgXX7HlgjCeV1', description: `Humble Futures Funding traders can obtain the Standard Performance Account on the same day they pass their evaluation by paying a one-time lifetime fee, with no ongoing monthly fees to keep the account active.` },
    { label: 'PREMIUM PERFORMANCE ACCOUNT (LIFETIME FEE + ONE DAY PASS)', price: '$210 ONE TIME FEE', href: 'https://buy.stripe.com/5kA5oi5ff6Dh5EYfZ6', description: `Humble Futures Funding traders can access the Premium Performance Account immediately after passing their evaluation by paying a one-time lifetime fee, eliminating the need for monthly fees to maintain account activity.` },
    { label: 'ACCOUNT RESET FEE', price: 'DEPENDS ON ACCOUNT SIZE & TYPE', href: 'https://discord.com/invite/ZvDDTuzFus', description: 'Reset your evaluation account to its initial state for a fresh start. Contact us through email(support@humblefunding.co) or through discord support to request an account reset.' },
    { label: 'SECOND MARKET DATA LOGIN SESSION', price: '$38/MONTH', href: 'https://buy.stripe.com/5kA2c67nn3r50kEcMW', description: `Second Login Session` },
    { label: '4 X $25K EVALUATION ACCOUNTS DEAL', price: '$58/MONTH', href: 'https://buy.stripe.com/9AQ6sm9vv7Hl1oIeV5', description: `ELIGIBLE FOR FIRST TIME CUSTOMERS ONLY, receive 4 25K Evaluation Accounts at a discounted price. Current price includes 90% discount.` }
  ];

  const renderStandard = () => (
    <Section>
      {standardAccounts.map(account => (
        <PaymentBox key={account.label}>
          <SubHeading>{account.label}</SubHeading>
          <ProductDescription>{account.description}</ProductDescription>
          <PriceTag>{account.price}</PriceTag>
          <Button href={account.href} disabled={!allChecked} type="standard">
            Subscribe {account.bonus ? `+ $${account.bonus} Bonus Capital` : ''}
          </Button>
        </PaymentBox>
      ))}
    </Section>
  );

  const renderExpress = () => (
    <Section>
      {expressAccounts.map(account => (
        <PaymentBox key={account.label}>
          <SubHeading>{account.label}</SubHeading>
          <ProductDescription>{account.description}</ProductDescription>
          <PriceTag>{account.price}</PriceTag>
          <Button href={account.href} disabled={!allChecked} type="express">
            Subscribe
          </Button>
        </PaymentBox>
      ))}
    </Section>
  );

  const renderInstantFunding = () => (
    <Section>
      {instantFundingAccounts.map(account => (
        <PaymentBox key={account.label}>
          <SubHeading>{account.label}</SubHeading>
          <ProductDescription>{account.description}</ProductDescription>
          <PriceTag>{account.price}</PriceTag>
          <Button href={account.href} disabled={!allChecked} type="instant">
            Subscribe
          </Button>
        </PaymentBox>
      ))}
    </Section>
  );

  const renderSpecialOffers = () => (
    <Section>
      {specialOffers.map(offer => (
        <PaymentBox key={offer.label}>
          <SubHeading>{offer.label}</SubHeading>
          <ProductDescription>{offer.description}</ProductDescription>
          <PriceTag>{offer.price}</PriceTag>
          <Button href={offer.href} disabled={!allChecked} type="special">
            {offer.label.includes('PERFORMANCE') ? 'Pay Activation Fee' : 'Subscribe'}
          </Button>
        </PaymentBox>
      ))}
    </Section>
  );

  return (
    <Container>
      <LeftContainer>
          <DiscountCodeContainer>
          <DiscountCodeTextWithMargin>STANDARD PATH 90% OFF DISCOUNT CODE: HMBL</DiscountCodeTextWithMargin>
          <DiscountCodeTextWithMargin>EXPRESS PATH 20% OFF DISCOUNT CODE: HMAS</DiscountCodeTextWithMargin>
        </DiscountCodeContainer>
        <LoadingText>OUR REGISTRATION & LOGIN SYSTEM WILL BE AVAILABLE SOON</LoadingText>
        <LoadingIcon />
        <CheckboxContainer>
          <Checkbox type="checkbox" checked={check3} onChange={(e) => setCheck3(e.target.checked)} />
          <StyledLink onClick={() => setIsModalOpen(true)}>CANCELLATION AND REFUND POLICY</StyledLink>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox type="checkbox" checked={check4} onChange={(e) => setCheck4(e.target.checked)} />
          <StyledLink onClick={() => setIsIndustryRegModalOpen(true)}>DATA DISCLAIMER</StyledLink>
        </CheckboxContainer>      
        <CheckboxContainer>
          <Checkbox type="checkbox" checked={check1} onChange={(e) => setCheck1(e.target.checked)} />
          I have read and agree to Humble Fundings&nbsp;<StyledLink onClick={() => setIsPayoutGDOpen(true)}>PAYOUT GUIDELINES</StyledLink>
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox type="checkbox" checked={check2} onChange={(e) => setCheck2(e.target.checked)} />
          I agree to Humble Fundings&nbsp;<StyledRouterLink to="/toss">TOS</StyledRouterLink> & <StyledRouterLink to="/faqq">FAQ</StyledRouterLink>
        </CheckboxContainer>
        <AffiliateLink as={StyledRouterLink} to="/affiliate-portal">
          AFFILIATE PORTAL
        </AffiliateLink>
        <AffiliateLink as={StyledRouterLink} to="/request-payout">
          PAYOUT REQUEST
        </AffiliateLink>
        <br></br>
        <StyledLink onClick={() => setIsCOMOpen(true)}>COMMISIONS FOR RITHMIC</StyledLink>
      </LeftContainer>
      <RightContainer>
        <Tabs>
          <Tab active={activeTab === 'standard'} onClick={() => setActiveTab('standard')}>Standard</Tab>
          <Tab active={activeTab === 'express'} onClick={() => setActiveTab('express')}>Express</Tab>
          <Tab active={activeTab === 'instantFunding'} onClick={() => setActiveTab('instantFunding')}>Instant Funding</Tab>
          <Tab active={activeTab === 'specialOffers'} onClick={() => setActiveTab('specialOffers')}>Special Offers</Tab>
        </Tabs>
        {activeTab === 'standard' && renderStandard()}
        {activeTab === 'express' && renderExpress()}
        {activeTab === 'instantFunding' && renderInstantFunding()}
        {activeTab === 'specialOffers' && renderSpecialOffers()}
      </RightContainer>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <BillingTOC />
      </Modal>
      <Modal show={isIndustryRegModalOpen} onClose={() => setIsIndustryRegModalOpen(false)}>
        <IndustryReg />
      </Modal>
      <Modal show={isPayoutGDOpen} onClose={() => setIsPayoutGDOpen(false)}>
        <PayoutGD />
      </Modal>
      <Modal show={isTOSOpen} onClose={() => setIsTOSOpen(false)}>
        <TOS />
      </Modal>
      <Modal show={isFAQOpen} onClose={() => setIsFAQOpen(false)}>
        <FAQ />
      </Modal>
      <Modal show={isCOMOpen} onClose={() => setIsCOMOpen(false)}>
        <COM />
      </Modal>
    </Container>
  );
};

export default PaymentPage;
