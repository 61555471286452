import styled from "styled-components";
import bronze from '../InfoSection/bronze.png'
import silver from '../InfoSection/star.png';
import gold from '../InfoSection/gold.png';

const gradientText = `
  background: linear-gradient(90deg, black, #00bfff);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

export const ServicesContainer = styled.div`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  margin-bottom: 100px;
`;

export const ServicesWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const DiscountPriceButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 1rem;
  padding: 16px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  margin-top: 15px;
  
  /* Background and border based on card type */
  background-color: ${({ theme }) => theme === 'bronze' ? '#cd7f32' : theme === 'silver' ? '#c0c0c0' : '#000'};
  color: ${({ theme }) => theme === 'gold' ? '#ffd700' : '#fff'}; 
  border: 2px solid ${({ theme }) => theme === 'bronze' ? '#cd7f32' : theme === 'silver' ? '#c0c0c0' : '#ffd700'};

  &:hover {
    transform: translateY(-5px);
    background-color: ${({ theme }) => theme === 'bronze' ? '#b87333' : theme === 'silver' ? '#a9a9a9' : '#333'};
  }
`;

export const OriginalPrice = styled.span`
  text-decoration: line-through;
  color: black;
  font-weight: bold;
`;

export const DiscountedPrice = styled.span`
  color: ${({ theme }) => theme === 'gold' ? '#ffd700' : '#fff'};
  font-weight: bold;
`;

export const ServicesCard = styled.div`
  position: relative;
  background-image: url(${bronze}); /* Bronze textured background */
  background-size: 300%; /* Match GoldCard scaling */
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Same layout as GoldCard */
  align-items: center;
  text-align: center;
  border-radius: 15px; /* Match the rounded corners of the other cards */
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Match box-shadow */
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  color: black; /* Keep text color */
  border: 2px solid #cd7f32; /* Bronze border */
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); /* Ensure readability on textured background */

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  &:hover {
    transform: scale(1.03); /* Same hover effect */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Enhanced shadow on hover */
  }
`;


export const ExpressCard = styled.div`
  position: relative;
  background-image: url(${silver}); /* Silver textured background */
  background-size: 1000%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  color: #000; /* Suitable text color */
  border: 2px solid #c0c0c0; /* Silver border */

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;

export const GoldCard = styled.div`
  position: relative;
  background-image: url(${gold}); /* Gold textured background */
  background-size: 300%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  color: #4f4f4f; /* Grey for readability */
  border: 2px solid #ffd700; /* Gold border */

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;


export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  ${gradientText}
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;

export const SaleText = styled.span`
  position: absolute;
  top: 15px;
  right: 15px;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  color: #fff;
  padding: 3px 9px;
  border-radius: 12px;
  font-size: 0.9rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 10;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
`;

export const CardTitle = styled.h2`
  color: black;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 20px;
`;

export const SubTitle = styled.h2`
  display: block;
  color: #00bfff;
  font-size: 1rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const WhiteBar = styled.div`
  width: 100%;
  height: 2px;
  background: white;
  margin: 10px 0; /* Adjusts the spacing */
`;


export const CardDetail = styled.p`
  font-size: 1rem;
  color: black;
  line-height: 1.2;
  text-align: left;
  margin: 8px 0;
  width: 100%;
`;

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

export const DetailLabel = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: black;
  margin-right: 4px;
  text-align: left;
`;

export const DetailValue = styled.span`
  font-size: 1rem;
  color: black;
  text-align: right;
  flex-grow: 1;
`;

export const BronzePriceButton = styled.button`
  background-color: #cd7f32; /* Bronze background */
  color: #fff; /* White text for readability */
  text-align: center;
  padding: 16px 20px;
  font-size: 1rem;
  border: 2px solid #cd7f32; /* Bronze border */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 5px;
  margin-top: 15px;

  &:hover {
    background-color: #b87333; /* Slightly darker bronze on hover */
    transform: translateY(-5px);
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;  /* Adds spacing between the tabs */
`;

export const Tab = styled.button`
  background: none;
  border: none;
  padding: 15px 30px;
  font-size: 20px;  /* Larger font size */
  font-weight: 700;  /* Thicker text */
  color: ${({ active, children }) =>
    active && children === 'Standard Path' ? '#cd7f32' : // Bronze for Standard Path
    active && children === 'Express Path' ? '#d3d3d3' : '#000'};  // Brighter Silver for Express Path
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: ${({ active }) => (active ? '4px solid' : 'none')};
  border-color: ${({ children }) =>
    children === 'Standard Path' ? '#cd7f32' : // Bronze border for Standard
    children === 'Express Path' ? '#d3d3d3' : '#ffd700'};  // Brighter Silver border for Express

  &:hover {
    color: ${({ children }) =>
      children === 'Standard Path' ? '#cd7f32' : // Bronze on hover for Standard
      children === 'Express Path' ? '#d3d3d3' : '#ffd700'};  // Brighter Silver on hover for Express
  }

  @media (max-width: 768px) {
    font-size: 18px;  /* Adjust font size for smaller screens */
    padding: 12px 25px;  /* Adjust padding for smaller screens */
  }
`;



export const ExpressCardTitle = styled.h2`
  color: black;  /* Bright silver text */
  font-size: 1.8rem;
  margin-bottom: 8px;
  margin-top: 20px;
`;

export const ExpressSubTitle = styled.h3`
  color: black;  /* Bright silver text */
  font-size: 1.1rem;
  margin-bottom: 15px;
  font-weight: 500;
`;

export const ExpressDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 12px 0;
`;

export const ExpressDetailLabel = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: black;  /* Bright silver text */
  text-align: left;
`;

export const ExpressDetailValue = styled.span`
  font-size: 1rem;
  color: black;  /* Bright silver text */
  text-align: right;
  flex-grow: 1;
`;

export const ExpressPriceButton = styled.button`
  background-color: #007acc; /* Blue background for express */
  color: #c0c0c0; /* Silver text color */
  padding: 16px 20px;
  font-size: 1rem;
  border: 2px solid #c0c0c0; /* Silver border */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 5px;
  margin-top: 15px;

  &:hover {
    background-color: #005f99; /* Slightly darker blue on hover */
    transform: translateY(-5px);
  }
`;

export const GoldPriceButton = styled.button`
  background-color: #000; /* Black background */
  color: #ffd700; /* Gold text */
  padding: 16px 20px;
  font-size: 1rem;
  border: 2px solid #ffd700; /* Gold border */
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 5px;
  margin-top: 15px;

  &:hover {
    background-color: #333; /* Slightly lighter black on hover */
    transform: translateY(-5px);
  }
`;

export const PopularTag = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffd700;  /* Gold background */
  color: #000;  /* Black text */
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

