import React from 'react';
import styled, { keyframes } from 'styled-components';

const commissionData = [
  // Equity Futures
  { category: "Equity Futures", symbol: "ES", description: "E-mini S&P 500", exchange: "CME", oneSide: "1.99", roundTurn: "3.98" },
  { category: "Equity Futures", symbol: "NKD", description: "Nikkei NKD", exchange: "CME", oneSide: "1.99", roundTurn: "3.98" },
  { category: "Equity Futures", symbol: "NQ", description: "E-mini NASDAQ 100", exchange: "CME", oneSide: "1.99", roundTurn: "3.98" },
  { category: "Equity Futures", symbol: "YM", description: "Mini-DOW", exchange: "CBOT", oneSide: "1.99", roundTurn: "3.98" },
  { category: "Equity Futures", symbol: "EMD", description: "E-mini Midcap 400", exchange: "CME", oneSide: "1.99", roundTurn: "3.98" },
  { category: "Equity Futures", symbol: "RTY", description: "Russell 2000", exchange: "CME", oneSide: "1.99", roundTurn: "3.98" },
  
  // Interest Rate Futures
  { category: "Interest Rate Futures", symbol: "ZT", description: "2-Year Note", exchange: "CBOT", oneSide: "1.36", roundTurn: "2.72" },
  { category: "Interest Rate Futures", symbol: "ZF", description: "5-Year Note", exchange: "CBOT", oneSide: "1.41", roundTurn: "2.82" },
  { category: "Interest Rate Futures", symbol: "ZN", description: "10-Year Note", exchange: "CBOT", oneSide: "1.51", roundTurn: "3.02" },
  { category: "Interest Rate Futures", symbol: "ZB", description: "30-Year Bond", exchange: "CBOT", oneSide: "1.72", roundTurn: "3.44" },
  { category: "Interest Rate Futures", symbol: "UB", description: "Ultra-Bond", exchange: "CBOT", oneSide: "1.56", roundTurn: "3.12" },
  
  // Currency Futures
  { category: "Currency Futures", symbol: "6A", description: "Australian Dollar", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  { category: "Currency Futures", symbol: "6B", description: "British Pound", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  { category: "Currency Futures", symbol: "6C", description: "Canadian Dollar", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  { category: "Currency Futures", symbol: "6E", description: "Euro FX", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  { category: "Currency Futures", symbol: "6J", description: "Japanese Yen", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  { category: "Currency Futures", symbol: "6S", description: "Swiss Franc", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  { category: "Currency Futures", symbol: "6N", description: "New Zealand Dollar", exchange: "CME", oneSide: "2.36", roundTurn: "4.72" },
  
  // Agricultural Futures
  { category: "Agricultural Futures", symbol: "HE", description: "Lean Hogs", exchange: "CME", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "LE", description: "Live Cattle", exchange: "CME", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "GF", description: "Feeder Cattle", exchange: "CME", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "ZC", description: "Corn", exchange: "CBOT", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "ZW", description: "Wheat", exchange: "CBOT", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "ZS", description: "Soybeans", exchange: "CBOT", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "ZM", description: "Soybean Meal", exchange: "CBOT", oneSide: "2.79", roundTurn: "5.58" },
  { category: "Agricultural Futures", symbol: "ZL", description: "Soybean Oil", exchange: "CBOT", oneSide: "2.79", roundTurn: "5.58" },
  
  // Energy Futures
  { category: "Energy Futures", symbol: "CL", description: "Crude Oil", exchange: "NYMEX", oneSide: "1.98", roundTurn: "3.96" },
  { category: "Energy Futures", symbol: "QM", description: "Mini Crude Oil", exchange: "NYMEX", oneSide: "1.96", roundTurn: "3.92" },
  { category: "Energy Futures", symbol: "MCL", description: "Mini Crude Oil", exchange: "NYMEX", oneSide: "0.51", roundTurn: "1.02" },
  { category: "Energy Futures", symbol: "NG", description: "Natural Gas", exchange: "NYMEX", oneSide: "1.98", roundTurn: "3.96" },
  { category: "Energy Futures", symbol: "QG", description: "E-mini Natural Gas", exchange: "NYMEX", oneSide: "1.26", roundTurn: "2.52" },
  { category: "Energy Futures", symbol: "HO", description: "Heating Oil", exchange: "NYMEX", oneSide: "1.98", roundTurn: "3.96" },
  { category: "Energy Futures", symbol: "RB", description: "New York Harbor", exchange: "NYMEX", oneSide: "1.98", roundTurn: "3.96" },
  
  // Metal Futures
  { category: "Metal Futures", symbol: "GC", description: "Gold", exchange: "COMEX", oneSide: "2.31", roundTurn: "4.62" },
  { category: "Metal Futures", symbol: "SI", description: "Silver", exchange: "COMEX", oneSide: "2.31", roundTurn: "4.62" },
  { category: "Metal Futures", symbol: "HG", description: "Copper", exchange: "COMEX", oneSide: "2.31", roundTurn: "4.62" },
  { category: "Metal Futures", symbol: "PL", description: "Platinum", exchange: "COMEX", oneSide: "2.31", roundTurn: "4.62" },
  { category: "Metal Futures", symbol: "PA", description: "Palladium", exchange: "COMEX", oneSide: "2.31", roundTurn: "4.62" },
  { category: "Metal Futures", symbol: "QI", description: "miNY Silver", exchange: "COMEX", oneSide: "1.51", roundTurn: "3.02" },
  { category: "Metal Futures", symbol: "QO", description: "miNY Gold", exchange: "COMEX", oneSide: "1.51", roundTurn: "3.02" },
  
  // Micro Futures
  { category: "Micro Futures", symbol: "MES", description: "Micro E-Mini S&P 500", exchange: "CME", oneSide: "0.51", roundTurn: "1.02" },
  { category: "Micro Futures", symbol: "MYM", description: "Micro E-Mini Dow Jones", exchange: "CME", oneSide: "0.51", roundTurn: "1.02" },
  { category: "Micro Futures", symbol: "MNQ", description: "Micro E-Mini Nasdaq-100", exchange: "CME", oneSide: "0.51", roundTurn: "1.02" },
  { category: "Micro Futures", symbol: "M2K", description: "Micro E-Mini Russell 2000", exchange: "CME", oneSide: "0.51", roundTurn: "1.02" },
  { category: "Micro Futures", symbol: "MGC", description: "E-Micro Gold", exchange: "CME", oneSide: "0.76", roundTurn: "1.52" },
  { category: "Micro Futures", symbol: "M6A", description: "E-Micro AUD/USD", exchange: "CME", oneSide: "0.42", roundTurn: "0.84" },
  { category: "Micro Futures", symbol: "M6E", description: "E-Micro EUR/USD", exchange: "CME", oneSide: "0.42", roundTurn: "0.84" },
  { category: "Micro Futures", symbol: "M6J", description: "E-Micro USD/JPY", exchange: "CME", oneSide: "0.42", roundTurn: "0.84" },
  { category: "Micro Futures", symbol: "MCL", description: "Micro Crude Oil", exchange: "NYMEX", oneSide: "0.51", roundTurn: "1.02" },
  
  // Cryptocurrency
  { category: "Cryptocurrency", symbol: "MBT", description: "Micro Bitcoin Futures", exchange: "CME", oneSide: "2.76", roundTurn: "5.52" },
  { category: "Cryptocurrency", symbol: "MET", description: "Micro Ether Futures", exchange: "CME", oneSide: "0.46", roundTurn: "0.92" },
];

const moveBackground = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const Container = styled.div`
  padding: 20px;
  min-height: 100vh;
  background: linear-gradient(90deg, #00bfff, white);
  background-size: 200% 200%;
  animation: ${moveBackground} 10s ease infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 10px;
    min-height: auto;
    height: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #004080;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
`;

const Table = styled.table`
  width: 100%;
  max-width: 1200px;
  border-collapse: collapse;
  margin-bottom: 40px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }
`;

const TableHead = styled.thead`
  background: #00bfff;
  color: white;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const TableHeader = styled.th`
  padding: 12px 15px;
  text-align: left;
  font-size: 1rem;
  border-bottom: 2px solid #ddd;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const TableBody = styled.tbody`
  tr:nth-child(even) {
    background: #f9f9f9;
  }
`;

const TableRow = styled.tr`
  &:hover {
    background: rgba(0, 191, 255, 0.2);
  }
`;

const TableData = styled.td`
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  text-align: left;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.9rem;
  }
`;

// Modal styling adjustments
const ModalWrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 95%;
  max-width: 800px;
  margin: auto;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
    max-height: 70vh;
  }
`;

const CommissionTable = () => {
  return (
    <ModalWrapper>
      <Container>
        <Title>Commission Schedule</Title>
        <Table>
          <TableHead>
            <tr>
              <TableHeader>Category</TableHeader>
              <TableHeader>Symbol</TableHeader>
              <TableHeader>Description</TableHeader>
              <TableHeader>Exchange</TableHeader>
              <TableHeader>1 Side ($)</TableHeader>
              <TableHeader>Round Turn ($)</TableHeader>
            </tr>
          </TableHead>
          <TableBody>
            {commissionData.map((commission, index) => (
              <TableRow key={index}>
                <TableData>{commission.category}</TableData>
                <TableData>{commission.symbol}</TableData>
                <TableData>{commission.description}</TableData>
                <TableData>{commission.exchange}</TableData>
                <TableData>{commission.oneSide}</TableData>
                <TableData>{commission.roundTurn}</TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>
    </ModalWrapper>
  );
};

export default CommissionTable;







