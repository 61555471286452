import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
`;

const Title = styled.h1`
  color: #333;
`;

const Message = styled.p`
  text-align: center;
  color: #666;
  max-width: 600px;
`;

const ContactButton = styled.button`
  background-color: #007bff;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    background-color: #0056b3;
  }
`;

function ForgotPass() {

  const handleContactUs = () => {
    window.location.href = "mailto:precisionfundingusa@gmail.com?subject=Forgotten Password";
  };

  return (
    <Container>
      <Title>Need a Hand with Your Account?</Title>
      <Message>"We all hit a bump in the road now and then. If you've forgotten your email or password, don't worry! Just hit the button below, and let's get you back on track."</Message>
      <ContactButton onClick={handleContactUs}>Contact Us</ContactButton>
    </Container>
  );
}

export default ForgotPass;
