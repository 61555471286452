import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for the gradient background animation
const moveBackground = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Styled Components
const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: linear-gradient(90deg, white, #00bfff);
  background-size: 200% 200%;
  animation: ${moveBackground} 10s linear infinite;
  color: #fff;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px;
  }
`;

const Button = styled.button`
  padding: 10px;
  background-color: #00bfff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #007acc;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const Heading = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  background: linear-gradient(to left, black, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const SubText = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: black;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const SocialMediaLabel = styled.label`
  font-size: 14px;
  color: #000;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const AffiliatePortal = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    youtube: '',
    instagram: '',
    tiktok: '',
    facebook: '',
    commissionRate: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mailtoLink = `mailto:humblefunding@gmail.com?subject=Affiliate Application from ${formData.name}&body=Name: ${formData.name}%0D%0AEmail: ${formData.email}%0D%0APhone: ${formData.phone}%0D%0AYoutube: ${formData.youtube}%0D%0AInstagram: ${formData.instagram}%0D%0ATikTok: ${formData.tiktok}%0D%0AFacebook: ${formData.facebook}%0D%0ACommission Rate: ${formData.commissionRate}%0D%0AMessage: ${formData.message}`;
    window.location.href = mailtoLink;
  };

  return (
    <Container>
      <Heading>Affiliate Portal (Beta)</Heading>
      <SubText>Apply to become an affiliate by filling out the form below. Put "none" if not applicable:</SubText>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Input
          type="tel"
          name="phone"
          placeholder="Your Phone Number"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <SocialMediaLabel>YouTube Channel:</SocialMediaLabel>
        <Input
          type="text"
          name="youtube"
          placeholder="YouTube Channel Link"
          value={formData.youtube}
          onChange={handleChange}
          required
        />

        <SocialMediaLabel>Instagram Profile:</SocialMediaLabel>
        <Input
          type="text"
          name="instagram"
          placeholder="Instagram Profile Link"
          value={formData.instagram}
          onChange={handleChange}
          required
        />

        <SocialMediaLabel>TikTok Profile:</SocialMediaLabel>
        <Input
          type="text"
          name="tiktok"
          placeholder="TikTok Profile Link"
          value={formData.tiktok}
          onChange={handleChange}
          required
        />

        <SocialMediaLabel>Facebook Profile:</SocialMediaLabel>
        <Input
          type="text"
          name="facebook"
          placeholder="Facebook Profile Link"
          value={formData.facebook}
          onChange={handleChange}
          required
        />

        <Input
          type="text"
          name="commissionRate"
          placeholder="Your Commission Rate with Other Firms"
          value={formData.commissionRate}
          onChange={handleChange}
          required
        />
        <TextArea
          name="message"
          rows="5"
          placeholder="Why do you want to become an affiliate?"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <Button type="submit">Apply to be an Affiliate</Button>
      </Form>
    </Container>
  );
};

export default AffiliatePortal;
